import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';
import {Input} from 'reactstrap';
import validator from 'validator';
import prependHttp from 'prepend-http';
import * as Apis from 'Api';

const SiteAddModal = ({onClose, onAddSite}) => {
    const [matchingList, setMatchingList] = useState([]);
    const [site, setSite] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        Apis.matchingList().then((resp) => {
            setMatchingList(resp);
        })
    }, []);

    const onSubmit = () => {
        if(!site) {
            setError(true);
            return;
        }
        onAddSite(prependHttp(site, {https: false}));
        onClose();
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>Site추가</DialogTitle>
            <DialogContent style={{minWidth: 400}}>
                <Input
                    type="select"
                    value={site}
                    onChange={(e) => setSite(e.target.value)}
                >
                    <option style={{display: 'none'}}>사이트선택</option>
                    {
                        matchingList.map((v) => <option value={v.site} key={v.id}>{v.site}</option>)
                    }
                </Input>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant={'contained'}>
                    취소
                </Button>
                <Button onClick={onSubmit} color="primary" variant={'contained'}>
                    추가
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SiteAddModal;