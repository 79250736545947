import React, {useState, useEffect, useRef} from "react";
import {Helmet} from "react-helmet";
import { FormControlLabel, Checkbox, Button} from "@material-ui/core";
import {Input, Col} from 'reactstrap';
import moment from "moment";
import {NavLink, useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import FileSaver from 'file-saver';
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import PaginationComponent from "Components/PaginationComponent";
import RctSectionLoader from "Components/RctSectionLoader/RctSectionLoader";
import * as Apis from 'Api';
import {logRemoveAll} from "Api";

const Log = (props) => {
    const history = useHistory();
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);
    const [searchUserName, setSearchUserName] = useState('');
    const [searchSite, setSearchSite] = useState('');
    const [searchSuccess, setSearchSuccess] = useState('all');
    const [searchStartDate, setSearchStartDate] = useState('');
    const [searchEndDate, setSearchEndDate] = useState('');
    const [distributorList, setDistributorList] = useState({});

    useEffect(() => {
        Apis.userDistributorList({}).then(resp => {
            const distObj = {}
            resp?.forEach((v) => {
                distObj[v.id] = v.username;
            })
            setDistributorList(distObj);
        });
    }, []);

    useEffect(() => {
        getData();
    }, [currentPage]);

    const getData = () => {
        const search = {
            searchUserName,
            searchSite,
            searchSuccess: searchSuccess === 'all' ? undefined : searchSuccess,
            searchStartDate: searchStartDate !== '' ? moment.utc(moment(searchStartDate)).format('YYYY-MM-DDTHH:mm') : '',
            searchEndDate: searchEndDate !== '' ? moment.utc(moment(searchEndDate)).format('YYYY-MM-DDTHH:mm') : '',
        }
        Apis.logList(currentPage, search).then((resp) => {
            setLoading(false);
            setItemList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        })
    }

    const onSelectAllItem = () => {
        let selectAll = selectedCount < itemList.length;
        if (selectAll) {
            let selectAllItems = itemList.map(item => {
                item.checked = true
                return item
            });
            setItemList(selectAllItems);
            setSelectedCount(selectAllItems.length);
        } else {
            let unselectedItems = itemList.map(item => {
                item.checked = false
                return item;
            });
            setSelectedCount(0);
            setItemList(unselectedItems);
        }
    }


    const onSelectItem = (item) => {
        item.checked = !item.checked;
        let selectedItemCount = 0;
        let allItems = itemList.map(v => {
            if (v.checked) {
                selectedItemCount++;
            }
            if (v.id === item.id) {
                if (v.checked) {
                    selectedItemCount++;
                }
                return item;
            } else {
                return v;
            }
        });
        setItemList(allItems);
        setSelectedCount(selectedItemCount);
    }

    const onReload = (e) => {
        e.preventDefault();
        getData();
    }

    const onExport = (e) => {
        e.preventDefault();
        const search = {
            searchUserName,
            searchSite,
            searchSuccess: searchSuccess === 'all' ? undefined : searchSuccess,
            searchStartDate: searchStartDate !== '' ? moment.utc(moment(searchStartDate)).format('YYYY-MM-DDTHH:mm') : '',
            searchEndDate: searchEndDate !== '' ? moment.utc(moment(searchEndDate)).format('YYYY-MM-DDTHH:mm') : '',
        }
        Apis.logExport(search).then((resp) => {
            if (resp.length > 0) {
                const byteCharacters = atob(resp);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(blob, "이력.xlsx");
            } else {
                NotificationManager.error('자료가 없습니다.')
            }
        }).catch((e) => {
            console.error(e);
            NotificationManager.error('오류가 발생했습니다.')
        })
    }

    const onRemoveLog = (e) => {
        e.preventDefault();
        Apis.logRemoveAll().then((resp) => {
            NotificationManager.success('모든 이력을 삭제했습니다.');
            getData()
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.')
        });
    }

    const onResetSearch = () => {
        setSearchUserName('');
        setSearchSite('');
        setSearchSuccess(null);
        setSearchStartDate('');
        setSearchEndDate('');
    }

    return (
        <div className="user-management">
            <Helmet>
                <title>게임내역</title>
                <meta name="description" content="user management" />
            </Helmet>
            <PageTitleBar
                title={"게임내역"}
                match={props.match}
            />
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="!#" onClick={(e) => onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"/></a>
                        </div>
                        <div className={'row'} style={{flex: 1}}>
                            <Col>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="회원아이디"
                                    value={searchUserName}
                                    onChange={(e) => setSearchUserName(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="사이트 URL"
                                    value={searchSite}
                                    onChange={(e) => setSearchSite(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Input
                                    type="select"
                                    placeholder="승패여부"
                                    value={searchSuccess}
                                    onChange={(e) => setSearchSuccess(e.target.value)}
                                >
                                    <option value={'all'}>전체</option>
                                    <option value={'true'}>적중</option>
                                    <option value={'false'}>미적중</option>
                                </Input>
                            </Col>
                            <Col>
                                <Input
                                    type="datetime-local"
                                    value={searchStartDate}
                                    onChange={(e) => setSearchStartDate(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Input
                                    type="datetime-local"
                                    value={searchEndDate}
                                    onChange={(e) => setSearchEndDate(e.target.value)}
                                />
                            </Col>
                            <Col className={'d-flex align-items-center justify-content-start pl-0'}>
                                <Button variant="contained" className="btn-success text-white pl-1 pr-1 mr-10" onClick={getData}>검색</Button>
                                <Button variant="contained" className="btn-secondary text-white pl-1 pr-1" onClick={onResetSearch}>재설정</Button>
                            </Col>
                        </div>
                        <div>
                            <a href="!#" onClick={e => onExport(e)} className="btn-sm btn-outline-default mr-10">Excel로 내보내기</a>
                            <a href="!#" onClick={(e) => onRemoveLog(e)} className="btn-sm btn-outline-danger mr-10">모든 이력 삭제</a>
                        </div>
                    </div>
                    <table className="table table-middle table-hover mb-0">
                        <thead>
                        <tr>
                            <th className="w-5">
                                <FormControlLabel
                                    className={'mb-0'}
                                    control={
                                        <Checkbox
                                            indeterminate={selectedCount > 0 && selectedCount < itemList.length}
                                            checked={selectedCount > 0}
                                            onChange={(e) => onSelectAllItem(e)}
                                            value="all"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                            </th>
                            <th className={'text-center'}>No</th>
                            <th className={'text-center'}>총판</th>
                            <th className={'text-center'}>회원아이디</th>
                            <th className={'text-center'}>계정</th>
                            <th className={'text-center'}>사이트URL</th>
                            <th className={'text-center'}>테이블명</th>
                            <th className={'text-center'}>회차</th>
                            <th className={'text-center'}>배팅액</th>
                            <th className={'text-center'}>현재금액</th>
                            <th className={'text-center'}>로직</th>
                            <th className={'text-center'}>패턴</th>
                            <th className={'text-center'}>픽정보</th>
                            <th className={'text-center'}>승패여부</th>
                            <th className={'text-center'}>배팅시간</th>
                        </tr>
                        </thead>
                        <tbody>
                        {itemList && itemList.map((item, key) => (
                            <tr key={key}>
                                <td>
                                    <FormControlLabel
                                        label=''
                                        control={
                                            <Checkbox
                                                checked={!!item.checked}
                                                onChange={() => onSelectItem(item)}
                                                color="primary"
                                            />
                                        }
                                    />
                                </td>
                                <td className={'text-center'}>{((currentPage - 1) * itemsPerPage) + key + 1}</td>
                                <td className={'text-center'}>{item.user ? distributorList[item.user.presenter_id] : ''}</td>
                                <td className={'text-center'}>{item.user ? item.user.username : ''}</td>
                                <td className={'text-center'}>{item.site_account}</td>
                                <td className={'text-center'}>{item.site_url}</td>
                                <td className={'text-center'}>{item.table_name}</td>
                                <td className={'text-center'}>{item.round}</td>
                                <td className={'text-center'}>{(item.betting_value || '').toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                                <td className={'text-center'}>{(item.current_money || '').toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                                <td className={'text-center'}>{item.logic}</td>
                                <td className={'text-center'}>{item.pattern}</td>
                                <td className={'text-center'}>{item.pick_info}</td>
                                <td className={'text-center'}><span style={{color: (item.is_success ? '#00C853' : '#F44336')}}>{item.is_success ? '적중' : '미적중'}</span></td>
                                <td className={'text-center'}>{moment(item.betting_time).format('YYYY-MM-DD HH:mm:ss')}</td>
                                {/*<td className="list-action">*/}
                                {/*    <button type="button" className="rct-link-btn" onClick={() => null}><i className="ti-pencil"/></button>*/}
                                {/*    <button type="button" className="rct-link-btn" onClick={() => null}><i className="ti-close"/></button>*/}
                                {/*</td>*/}
                            </tr>
                        ))}
                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                <PaginationComponent
                                    totalItems={totalItemCount}
                                    pageSize={itemsPerPage}
                                    onSelect={(page) => setCurrentPage(page)}
                                    maxPaginationNumbers={7}
                                    activePage={currentPage}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                {loading &&
                <RctSectionLoader />
                }
            </RctCollapsibleCard>
        </div>
    );
}

export default Log;