import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from "react-helmet";
import {Button} from '@material-ui/core';
import {Col, FormGroup, Input, Label} from "reactstrap";
import socketIOClient from 'socket.io-client';
import moment from 'moment';
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import {NotificationManager} from "react-notifications";
import * as Apis from 'Api';

const tableIdList = {
    "p63cmvmwagteemoy": "코리안 스피드 바카라 A",
    "onokyd4wn7uekbjx": "코리안 스피드 바카라 B",
    "qgdk6rtpw6hax4fe": "코리안 스피드 바카라 C",
    "q25awuwygsy3lvnj": "코리안 스피드 바카라 D",
    "q25bmd63gsy3ngfl": "코리안 스피드 바카라 E",
    "q7jgx3w4fk7nmrz2": "코리안 스피드 바카라 F",
    "leqhceumaq6qfoug": "스피드 바카라 A",
    "lv2kzclunt2qnxo5": "스피드 바카라 B",
    "ndgvwvgthfuaad3q": "스피드 바카라 C",
    "ndgvz5mlhfuaad6e": "스피드 바카라 D",
    "ndgv45bghfuaaebf": "스피드 바카라 E",
    "nmwde3fd7hvqhq43": "스피드 바카라 F",
    "nmwdzhbg7hvqh6a7": "스피드 바카라 G",
    "nxpj4wumgclak2lx": "스피드 바카라 H",
    "nxpkul2hgclallno": "스피드 바카라 I",
    "obj64qcnqfunjelj": "스피드 바카라 J",
    "ocye2ju2bsoyq6vv": "스피드 바카라 K",
    "ovu5cwp54ccmymck": "스피드 바카라 L",
    "ovu5dsly4ccmynil": "스피드 바카라 M",
    "ovu5eja74ccmyoiq": "스피드 바카라 N",
    "ovu5fbxm4ccmypmb": "스피드 바카라 O",
    "ovu5fzje4ccmyqnr": "스피드 바카라 P",
    "o4kyj7tgpwqqy4m4": "스피드 바카라 Q",
    "o4kylkahpwqqy57w": "스피드 바카라 R",
    "o4kymodby2fa2c7g": "스피드 바카라 S",
    "qgonc7t4ucdiel4o": "스피드 바카라 T",
    "qgqrhfvsvltnueqf": "스피드 바카라 U",
    "qgqrrnuqvltnvejx": "스피드 바카라 V",
    "qgqrucipvltnvnvq": "스피드 바카라 W",
    "qgqrv4asvltnvuty": "스피드 바카라 X",
    "oytmvb9m1zysmc44": "바카라 A",
    "60i0lcfx5wkkv3sy": "바카라 B",
    "ndgvs3tqhfuaadyg": "바카라 C",
    "LightningBac0001": "라이트닝 바카라",
    "puu4yfymic3reudn": "엠퍼러 스피드 바카라 A",
    "puu43e6c5uvrfikr": "엠퍼러 스피드 바카라 B",
    "pwsaqk24fcz5qpcr": "엠퍼러 스피드 바카라 C",
    "q6ardco6opnfwes4": "엠퍼러 스피드 바카라 D",
    "BonsaiBacc000001": "본자이 스피드 바카라 A",
    "BonsaiBacc000002": "본자이 스피드 바카라 B",
    "BonsaiBacc000003": "본자이 스피드 바카라 C",
    "qsf65xtoyvbqoaop": "스피드 바카라 1",
    "qsf7alptyvbqohva": "스피드 바카라 2",
    "qsf7bpfvyvbqolwp": "스피드 바카라 3",
    "rdjda6zq7jdyo6cs": "스피드 바카라 4",
    "rep45wbxnyjl7hr2": "스피드 바카라 5",
    "rep5aor7nyjl7qli": "스피드 바카라 6",
    "rep5ca4ynyjl7wdw": "스피드 바카라 7",
    "rep5eiecnyjl75lq": "스피드 바카라 8",
    "rep5iuhinyjmalz4": "스피드 바카라 10",
    "rep5kwmdnyjmasxi": "스피드 바카라 11",

    "bcpirpmfpobc1910": "포츈6바카라",
    "bcpirpmfpeobc199": "슈퍼 8 바카라",
    "pwnhicogrzeodk79": "스피드 바카라 1",
    "kkqnazmd8ttq7fgd": "스피드 바카라 2",
    "s8s9f0quk3ygiyb1": "스피드 바카라 3",
    "bcpirpmfpeobc196": "스피드 바카라 9",
    "886ewimul28yw14j": "스피드 바카라 5",
    "2q57e43m4ivqwaq3": "스피드 바카라 6",
    "bcpirpmfpeobc197": "스피드 바카라 7",
    "bcpirpmfpeobc198": "스피드 바카라 8",
    "bcpirpmfpeobc194": "스피드 바카라 10",
    "cbcf6qas8fscb224": "스피드 바카라 11",
    "cbcf6qas8fscb221": "스피드 바카라 12",
    "m88hicogrzeod202": "스피드 바카라 13",
    "b0jf7rlboleibnap": "스피드 바카라 14",
    "bcpirpmfpeobc193": "스피드 바카라 15",
    "bcpirpmfpobc1911": "스피드 바카라 16",
    "bcpirpmfpebc1908": "스피드 바카라 17",

    "LightningTable01": "라이트닝 룰렛",
    "vctlz20yfnmp1ylr": "룰렛",
    "7x0b1tgh7agmf6hv": "이머지브 룰렛",
    "pv5q45yjhasyt46y": "엠퍼러 룰렛",
    "InstantRo0000001": "인스턴트 룰렛",
    "48z5pjps3ntvqc1b": "오토 룰렛",
    "lkcbrbdckjxajdol": "스피드 룰렛",
    "wzg6kdkad1oe7m5k": "VIP 룰렛",
    "AmericanTable001": "아메리칸 룰렛",
    "DoubleBallRou001": "더블 볼 룰렛",
    "SpeedAutoRo00001": "스피드 자동 룰렛",
    "f1f4rm9xgh4j3u2z": "오토 룰렛 라 파르타지",
    "01rb77cq1gtenhmo": "오토 룰렛 VIP",
    "mdkqijp3dctrhnuv": "살롱 프라이브 룰렛",
    "mrpuiwhx5slaurcy": "히포드롬 그랜드 룰렛",
    "pnkk4iuchw7blb2p": "드라고나라 룰렛",
}

const LobbyWs = (props) => {
    const dataNo = useRef();
    const [socket, setSocket] = useState(null);
    const [socketList, setSocketList] = useState([]);

    useEffect(() => {
        dataNo.current = 1;
        setSocket(socketIOClient('http://ws.5874.kr/', {
            transports: ["websocket"]
        }));
        return () => {
            if (socket) {
                socket.disconnect();
            }
        }
    }, []);

    useEffect(() => {
        if (!socket) return;
        socket.on('MSG', handleSocketMessage);
        return () => {
            socket.off('MSG', handleSocketMessage);
        };
    }, [socket]);

    const handleSocketMessage = (data) => {
        data.no = dataNo.current;
        dataNo.current = dataNo.current + 1;
        socketList.unshift(data);
        if (socketList.length > 30) {
            socketList.pop();
        }
        setSocketList([...socketList]);
    }

    const restartSocket = () => {
        Apis.restartRobby().then((resp) => {
            NotificationManager.success('소켓을 재시작했습니다.');
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        })
    }

    return (
        <div>
            <Helmet>
                <title>소켓정보확인</title>
                <meta name="description" content="user management"/>
            </Helmet>
            <PageTitleBar
                title={"소켓정보확인"}
                match={props.match}
            />
            <RctCollapsibleCard>
                <div className={'d-flex flex-column'}>
                    <div className={'mb-2'}>
                        <Button variant={'contained'} color={'primary'} onClick={restartSocket}>소켓 재시작</Button>
                    </div>
                </div>
                <table className="table table-middle table-hover mb-0">
                    <thead>
                    <tr>
                        <th className={'text-center'}>No</th>
                        <th className={'text-center'}>사이트</th>
                        <th className={'text-center'}>테이블아이디</th>
                        <th className={'text-center'}>테이블명</th>
                        <th className={'text-center'}>패턴</th>
                        <th className={'text-center'}>시간</th>
                        <th className={'text-center'}>라운드</th>
                    </tr>
                    </thead>
                    <tbody>
                    {socketList.map((item, key) => (
                        <tr key={key}>
                            <td className={'text-center'}>{item.no}</td>
                            <td className={'text-center'}>{['gkaa33.com', '888-kaka.com', 'top-010.com'][item.siteType]}</td>
                            <td className={'text-center'}>{item.tableId}</td>
                            <td className={'text-center'}>{tableIdList[item.tableId]}</td>
                            <td className={'text-center text-ellipsis'}>{item.pattern}</td>
                            <td className={'text-center'}>{moment(item.time).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td className={'text-center'}>{item.totalRound}</td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot className="border-top">
                    </tfoot>
                </table>
            </RctCollapsibleCard>
        </div>
    );
};

export default LobbyWs;