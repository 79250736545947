import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import {Input} from 'reactstrap';


function NoticeSendModal({open, onClose, onSend}) {
    const [text, setText] = useState('');

    useEffect(() => {
        setText('');
    }, [open])

    const onSubmit = () => {
        if(text === '') return;
        onSend(text)
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                알림보내기
            </DialogTitle>
            <DialogContent>
                <Input
                    type={'textarea'}
                    style={{width: 350, minHeight: 100}}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'primary'} className={'mr-3'} onClick={onSubmit}>전송</Button>
            </DialogActions>
        </Dialog>
    )
}

export default NoticeSendModal;