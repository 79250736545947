import React, {useState, useEffect, useRef} from "react";
import {Helmet} from "react-helmet";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import {NotificationManager} from "react-notifications";
import FileSaver from 'file-saver';
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import PaginationComponent from "Components/PaginationComponent";
import RctSectionLoader from "Components/RctSectionLoader/RctSectionLoader";
import * as Apis from 'Api';
import {accountExport} from "Api";

const AccountList = (props) => {
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);


    useEffect(() => {
        getData();
    }, [currentPage]);

    const getData = () => {
        Apis.accountList(currentPage).then((resp) => {
            setLoading(false);
            setItemList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        })
    }

    const onSelectAllItem = () => {
        let selectAll = selectedCount < itemList.length;
        if (selectAll) {
            let selectAllItems = itemList.map(item => {
                item.checked = true
                return item
            });
            setItemList(selectAllItems);
            setSelectedCount(selectAllItems.length);
        } else {
            let unselectedItems = itemList.map(item => {
                item.checked = false
                return item;
            });
            setSelectedCount(0);
            setItemList(unselectedItems);
        }
    }


    const onSelectItem = (item) => {
        item.checked = !item.checked;
        let selectedItemCount = 0;
        let allItems = itemList.map(v => {
            if (v.checked) {
                selectedItemCount++;
            }
            if (v.id === item.id) {
                if (v.checked) {
                    selectedItemCount++;
                }
                return item;
            } else {
                return v;
            }
        });
        setItemList(allItems);
        setSelectedCount(selectedItemCount);
    }

    const onReload = (e) => {
        e.preventDefault();
        getData();
    }

    const onExport = (e) => {
        e.preventDefault();
        Apis.accountExport().then((resp) => {
            if (resp.length > 0) {
                const byteCharacters = atob(resp);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(blob, "계정.xlsx");
            } else {
                NotificationManager.error('자료가 없습니다.')
            }
        }).catch((e) => {
            console.error(e);
            NotificationManager.error('오류가 발생했습니다.')
        })
    }

    return (
        <div className="user-management">
            <Helmet>
                <title>계정관리</title>
                <meta name="description" content="user management" />
            </Helmet>
            <PageTitleBar
                title={"계정관리"}
                match={props.match}
            />
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="!#" onClick={(e) => onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"/></a>
                        </div>
                        <div>
                            <a href="!#" onClick={e => onExport(e)} className="btn-sm btn-outline-default mr-10">Excel로 내보내기</a>
                        </div>
                    </div>
                    <table className="table table-middle table-hover mb-0">
                        <thead>
                        <tr>
                            <th className="w-5">
                                <FormControlLabel
                                    className={'mb-0'}
                                    control={
                                        <Checkbox
                                            indeterminate={selectedCount > 0 && selectedCount < itemList.length}
                                            checked={selectedCount > 0}
                                            onChange={(e) => onSelectAllItem(e)}
                                            value="all"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                            </th>
                            <th className={'text-center'}>회원아이디</th>
                            <th className={'text-center'}>사이트URL</th>
                            <th className={'text-center'}>사이트계정</th>
                            <th className={'text-center'}>사이트암호</th>
                            <th className={'text-center'}>변경시간</th>
                        </tr>
                        </thead>
                        <tbody>
                        {itemList && itemList.map((item, key) => (
                            <tr key={key}>
                                <td>
                                    <FormControlLabel
                                        label=''
                                        control={
                                            <Checkbox
                                                checked={!!item.checked}
                                                onChange={() => onSelectItem(item)}
                                                color="primary"
                                            />
                                        }
                                    />
                                </td>
                                <td className={'text-center'}>{item.user_name}</td>
                                <td className={'text-center'}>{item.url}</td>
                                <td className={'text-center'}>{item.account}</td>
                                <td className={'text-center'}>{item.password}</td>
                                <td className={'text-center'}>{moment(item.updated_at).format('YYYY-MM-DD hh:mm:ss')}</td>
                                {/*<td className="list-action">*/}
                                {/*    <button type="button" className="rct-link-btn" onClick={() => null}><i className="ti-pencil"/></button>*/}
                                {/*    <button type="button" className="rct-link-btn" onClick={() => null}><i className="ti-close"/></button>*/}
                                {/*</td>*/}
                            </tr>
                        ))}
                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                <PaginationComponent
                                    totalItems={totalItemCount}
                                    pageSize={itemsPerPage}
                                    onSelect={(page) => setCurrentPage(page)}
                                    maxPaginationNumbers={7}
                                    activePage={currentPage}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                {loading &&
                <RctSectionLoader />
                }
            </RctCollapsibleCard>
        </div>
    );
}

export default AccountList;