/**
 * App Header
 */
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, IconButton, Drawer, AppBar, Toolbar, Tooltip} from '@material-ui/core';
import {Link, withRouter} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import UserBlock from './UserBlock';
import {collapsedSidebarAction} from 'Store/Actions';

function Header(props) {

    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);

    // function to change the state of collapsed sidebar
    const onToggleNavCollapsed = (event) => {
        const val = settings.navCollapsed ? false : true;
        dispatch(collapsedSidebarAction(val));
    }


    // close dashboard overlay
    const closeDashboardOverlay = () => {
        var e = document.getElementsByClassName('dashboard-overlay')[0];
        e.classList.remove('show');
        e.classList.add('d-none');
        document.body.style.overflow = "";
    }

    return (
        <AppBar position="static" className="rct-header">
            <Toolbar className="d-flex justify-content-between w-100 pl-0">
                <div className="d-inline-flex align-items-center">
                    <ul className="list-inline mb-0 navbar-left">
                        <li className="list-inline-item" onClick={(e) => onToggleNavCollapsed(e)}>
                            <Tooltip title="Sidebar Toggle" placement="bottom">
                                <IconButton color="inherit" mini="true" aria-label="Menu" className="humburger p-0">
                                    <MenuIcon/>
                                </IconButton>
                            </Tooltip>
                        </li>
                    </ul>
                </div>
                <ul className="navbar-right list-inline mb-0">
                    <UserBlock/>
                </ul>
            </Toolbar>
        </AppBar>
    );
}

export default withRouter(Header);
