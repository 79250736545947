import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import {Button} from "@material-ui/core";
import {useParams, useHistory} from "react-router-dom";
import {FormGroup, Input, Label, Col} from "reactstrap";
import {NotificationManager} from 'react-notifications';
import * as Apis from 'Api';

const Setting = (props) => {
    const history = useHistory();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const onChangePassword = () => {
        if(newPassword.length === 0 || newPassword !== confirmPassword) {
            NotificationManager.error('입력한 정보가 정확하지 않습니다.')
            return;
        }
        Apis.changePassword(oldPassword, newPassword).then((resp) => {
            if(resp.result === 'failed') {
                NotificationManager.error(resp.msg);
            } else {
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                NotificationManager.success('암호가 변경되였습니다.')
            }
        }).catch((e) => {
            console.error(e);
            NotificationManager.error('오류가 발생했습니다.')
        })
    }

    return (
        <div>
            <Helmet>
                <title>관리자설정</title>
                <meta name="description" content="user management"/>
            </Helmet>
            <div className='row justify-content-between'>
                <PageTitleBar
                    title="관리자설정"
                    match={props.match}
                />
            </div>
            <RctCollapsibleCard fullBlock contentCustomClasses={'p-50'}>
                <FormGroup row>
                    <Label for="userEmail" sm={1}>현재암호</Label>
                    <Col sm={3}>
                        <Input
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="userEmail" sm={1}>새암호</Label>
                    <Col sm={3}>
                        <Input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="userEmail" sm={'1'}>암호확인</Label>
                    <Col sm={3}>
                        <Input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Col>
                </FormGroup>
                <Button variant="contained" className="btn-success text-white" onClick={onChangePassword}>변경</Button>
            </RctCollapsibleCard>
        </div>
    )
}

export default Setting;