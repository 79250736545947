import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';
import {Input} from 'reactstrap';

const PatternAddModal = ({onClose, onAddPattern, initPattern}) => {
    const [pattern, setPatten] = useState(initPattern);
    const [errorPattern, setErrorPattern] = useState(false);

    const onSubmit = () => {
        const patternMatch = pattern.match(/[BP\-]+/);
        if(patternMatch === null || patternMatch[0] !== pattern) {
            setErrorPattern(true);
            return;
        }
        onAddPattern(pattern);
        onClose();
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>패턴추가</DialogTitle>
            <DialogContent>
                <Input
                    style={{width: 500}}
                    type={'text'}
                    placeholder={'B, P, - 만 포함되여야 합니다.'}
                    invalid={errorPattern}
                    value={pattern}
                    onChange={(e) => {setPatten(e.target.value); setErrorPattern(false)}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant={'contained'}>
                    취소
                </Button>
                <Button onClick={onSubmit} color="primary" variant={'contained'}>
                    {initPattern === '' ? '추가' : '수정' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PatternAddModal;