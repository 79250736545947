import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import {Col, FormGroup, Input, Label} from "reactstrap";
import moment from "moment";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, IconButton} from "@material-ui/core";
import SiteAddModal from "../group/SiteAddModal";
import PatternAddModal from "../group/PatternAddModal";
import * as Apis from "Api";
import {userInfo} from "Api";
import {NotificationManager} from "react-notifications";
import PatternTemplateModal from "./PatternTemplateModal";


function UserEdit() {
    const history = useHistory();
    const userId = useParams().user_id;
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [patternList, setPatternList] = useState([]);
    const [agentId, setAgentId] = useState();
    const [groupId, setGroupId] = useState();
    const [expireDate, setExpireDate] = useState();
    const [sites, setSites] = useState([]);

    const [agentList, setAgentList] = useState([]);
    const [groupList, setGroupList] = useState([]);

    const [isChangeSite, setIsChangeSite] = useState(false);

    const [showAddSiteModal, setShowAddSiteModal] = useState(false);
    const [showAdminPassModal, setShowAdminPassModal] = useState(false);
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [editPatternId, setEditPatternId] = useState(null);
    const [adminPass, setAdminPass] = useState('');

    useEffect(() => {
        Apis.groupList().then((resp) => {
            setGroupList(resp);
        });
        Apis.agentList().then((resp) => {
            setAgentList(resp);
        });
        if(userId) {
            getData();
        }
    }, []);

    const getData = () => {
        Apis.userInfo(userId).then((resp) => {
            setId(resp.username);
            setAgentId(resp.agent_id);
            setGroupId(resp.group_id);
            setExpireDate(resp.expire_date);
            setPatternList(resp.pattern ? resp.pattern.split(',') : []);
            setSites(resp.sites ? resp.sites.split(',') : []);
        })
    }

    const validation = () => {
        let valid = true;
        if(id !== '' && groupId !== '' && expireDate && expireDate !== '') {
            if(!userId && password === '') {
                NotificationManager.error('암호를 입력하세요.');
                valid = false;
            }
            if (password !== '' && password !== confirmPassword) {
                NotificationManager.error('암호가 일치하지 않습니다.');
                valid = false;
            }
        } else {
            console.log(expireDate)
            NotificationManager.error('정보를 모두 입력하세요.');
            valid = false;
        }
        return valid;
    }

    const onSave = () => {
        if (validation()) {
            setShowAdminPassModal(true);
        }
    }

    const onSaveConfirm = () => {
        setShowAdminPassModal(false);
        if (!validation()) return
        let userInfo = {
            username: id,
            password: password !== '' ? password : undefined,
            pattern: patternList.join(','),
            sites: sites.join(','),
            group_id: groupId,
            agent_id: agentId !== '' ? agentId : undefined,
            expire_date: expireDate,
            adminPassword: adminPass
        }
        let promise
        if(!userId) {
            userInfo.user_type = 'USER';
            promise = Apis.userAdd(userInfo)
        } else {
            promise = Apis.userUpdate(userId, userInfo);
        }
        promise.then((resp) => {
            NotificationManager.success('저장되었습니다.');
        }).catch((e) => {
            NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
        }).finally(() => {
            getData();
            setAdminPass('')
        });
    }

    const onSaveSite = () => {
        Apis.userSiteUpdate(userId, sites.join(',')).then((resp) => {
            NotificationManager.success('저장되었습니다.');
        }).catch((e) => {
            NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
        });
    }

    const onAddTemplate = (p) => {
        if(!p) return;
        setPatternList(patternList.concat(p.split(',')));
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: `10px 0`,
        fontSize: 13,
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",
        borderBottom: '1px solid #dddddd',
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#eeeeee" : "white",
        padding: 0,
        // width: 250
    });

    const onSiteDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            sites,
            result.source.index,
            result.destination.index
        );
        setIsChangeSite(true);
        setSites(newItems);
    }

    const onChangeSite = (url, index) => {
        setIsChangeSite(true);
        const newSites = [...sites];
        if (index === -1) {
            newSites.push(url);
            setSites(newSites);
        } else {
            if (window.confirm('정말 삭제하시겠습니까?')) {
                newSites.splice(index, 1);
                setSites(newSites);
            }
        }
    }

    const onChangePattern = (p, index) => {
        const newPattenList = [...patternList];
        if (index === -1) {
            newPattenList.push(p);
            setPatternList(newPattenList);
        } else {
            if(p === null) {
                if (window.confirm('정말 삭제하시겠습니까?')) {
                    newPattenList.splice(index, 1);
                    setPatternList(newPattenList);
                }
            } else {
                newPattenList[index] = p;
                setPatternList(newPattenList);
            }
        }
    }


    const renderSite = () => {
        return (
            <div>
                <div className={'row m-0 bg-light border-top border-bottom'} style={{padding: '10px 0'}}>
                    <div className={'col-1 text-center'}>No</div>
                    <div className={'col-9 text-center'}>URL</div>
                    <div className={'col-2 text-center'}>관리</div>
                </div>
                <DragDropContext onDragEnd={onSiteDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                {sites.map((item, index) => (
                                    <Draggable key={item} draggableId={item} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="row m-0"
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div className={'col-1 text-center'}>{index + 1}</div>
                                                <div className={'col-9 text-center'}>{item}</div>
                                                <div className={'col-2 text-center'}>
                                                    <div>
                                                        <IconButton className={'text-danger'} size={'small'} onClick={() => onChangeSite(item, index)}>
                                                            <Icon>delete_outline</Icon>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
        return (
            <table className="table table-middle table-hover mb-0">
                <thead>
                <tr>
                    <th className={'text-center'}>URL</th>
                    <th className={'text-center'}>관리</th>
                </tr>
                </thead>
                <tbody>
                {
                    [].map((v, index) => (
                        <tr key={index}>
                            <td className={'text-center'}>{v}</td>
                            <td className={'text-center'}>
                                <IconButton className={'text-danger'} size={'small'} onClick={() => onChangeSite(v, index)}>
                                    <Icon>delete_outline</Icon>
                                </IconButton>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        )
    }

    return (
        <div>
            <Helmet>
                <title>회원관리</title>
                <meta name="description" content="user management"/>
            </Helmet>
            <div className='row justify-content-between'>
                <PageTitleBar
                    title={<span><span>회원관리</span> <i className="ti-angle-right fs-14"/> {userId ? id : '추가'}</span>}
                />
            </div>
            <RctCollapsibleCard heading={'기본정보'} onSave={onSave}>
                <div className={'d-flex flex-direction-row'}>
                    <FormGroup className={''} style={{width: 250, marginRight: 100}}>
                        <Label for="name">아이디</Label>
                        <Input
                            type="text"
                            className={'mt-0'}
                            value={id}
                            onChange={(e) => {
                                setId(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup className={'mr-2'} style={{width: 250}}>
                        <Label for="name">암호</Label>
                        <Input
                            type="password"
                            className={'mt-0'}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup className={'mr-2'} style={{width: 250}}>
                        <Label for="name">암호확인</Label>
                        <Input
                            type="password"
                            className={'mt-0'}
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                        />
                    </FormGroup>
                </div>
                <div className={'d-flex flex-direction-row'}>
                    <FormGroup className={'mr-3'} style={{width: 250}}>
                        <Label for="userType">에이전트</Label>
                        <Input
                            type="select"
                            value={agentId}
                            onChange={(e) => setAgentId(e.target.value)}
                        >
                            <option style={{display: 'none'}}>에이전트선택</option>
                            {
                                agentList.map((v) => <option value={v.id} key={v.id}>{v.agent_name}</option>)
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup className={'mr-3'} style={{width: 250}}>
                        <Label for="userType">그룹명</Label>
                        <Input
                            type="select"
                            value={groupId}
                            onChange={(e) => setGroupId(e.target.value)}
                        >
                            <option style={{display: 'none'}}>그룹선택</option>
                            {
                                groupList.map((v) => <option value={v.id} key={v.id}>{v.name}</option>)
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup className={'mr-3'} style={{width: 250}}>
                        <Label for="accountType">종료일</Label>
                        <Input
                            type="date"
                            value={moment(expireDate).format('YYYY-MM-DD')}
                            onChange={(e) => setExpireDate(e.target.value)}
                        />
                    </FormGroup>
                </div>
            </RctCollapsibleCard>
            <div className={'row'}>
                <RctCollapsibleCard
                    colClasses={'col'}
                    heading={'패턴 List'}
                    customStyle={{height: '100%'}}
                >
                    <div className={'d-flex flex-row justify-content-end'} style={{marginTop: -55, paddingBottom: 25}}>
                        <Button variant={'outlined'} size={'small'} onClick={(e) => setShowTemplateModal(true)} className={'ml-2'}>템플릿추가</Button>
                        <Button variant={'outlined'} size={'small'} onClick={(e) => setEditPatternId(-1)} className={'ml-2'}>추가</Button>
                        <Button variant={'outlined'} size={'small'} onClick={onSave} className={'ml-2'}>저장</Button>
                    </div>
                    <table className="table table-middle table-hover mb-0">
                        <thead>
                        <tr>
                            <th className={'text-center'}>No</th>
                            <th className={'text-center'}>패턴</th>
                            <th className={'text-center'}>관리</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            patternList.map((v, index) => (
                                <tr key={index}>
                                    <td className={'text-center'}>{index + 1}</td>
                                    <td className={'text-center'}>{v}</td>
                                    <td className={'text-center'}>
                                        <IconButton className={'text-indigo '} size={'small'} onClick={() => setEditPatternId(index)}>
                                            <Icon>edit_outline</Icon>
                                        </IconButton>
                                        <IconButton className={'text-danger'} size={'small'} onClick={() => onChangePattern(null, index)}>
                                            <Icon>delete_outline</Icon>
                                        </IconButton>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </RctCollapsibleCard>
                <RctCollapsibleCard
                    colClasses={'col'}
                    heading={'Site list'} onAdd={() => setShowAddSiteModal(true)}
                    onSave={userId ? onSaveSite : undefined}
                >
                    {renderSite()}
                </RctCollapsibleCard>
            </div>
            <Dialog
                open={showAdminPassModal}
                onClose={() => {setAdminPass(''); setShowAdminPassModal(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">관리자확인</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        그룹정보를 변경하려면 관리자암호를 입력해야 합니다.
                    </DialogContentText>
                    <FormGroup row>
                        <Label for="userEmail" sm={4}>관리자암호</Label>
                        <Col sm={8}>
                            <Input
                                type="password"
                                value={adminPass}
                                onChange={(e) => setAdminPass(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setAdminPass(''); setShowAdminPassModal(false); }} className="btn-danger text-white">
                        취소
                    </Button>
                    <Button onClick={onSaveConfirm} className="btn-primary text-white" autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
            {
                showAddSiteModal &&
                <SiteAddModal onAddSite={(url) => onChangeSite(url, -1)} onClose={() => setShowAddSiteModal(false)}/>
            }
            {
                editPatternId !== null &&
                <PatternAddModal
                    initPattern={editPatternId !== -1 ? patternList[editPatternId] : ''}
                    onAddPattern={(p) => onChangePattern(p, editPatternId)}
                    onClose={() => setEditPatternId(null)}
                />
            }
            {
                showTemplateModal &&
                <PatternTemplateModal
                    open={showTemplateModal}
                    onClose={() => setShowTemplateModal(false)}
                    onAddPattern={(p) => onAddTemplate(p)}
                />
            }
        </div>
    )
}

export default UserEdit;