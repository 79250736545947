import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Button, Checkbox, FormControlLabel} from '@material-ui/core';
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import {Input} from "reactstrap";
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";
import moment from "moment";
import PaginationComponent from "../../../Components/PaginationComponent";

const Statistic = () => {
    const [itemList, setItemList] = useState([]);
    const [totalData, setTotalData] = useState({total: 0, win: 0, lose: 0});
    const [searchUsername, setSearchUsername] = useState('');
    const [searchStartDate, setSearchStartDate] = useState('');
    const [searchEndDate, setSearchEndDate] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        getData();
    }, [currentPage]);

    const getData = () => {
        const startDate = searchStartDate !== '' ? moment.utc(moment(searchStartDate)).format('YYYY-MM-DDTHH:mm') : '';
        const endDate = searchEndDate !== '' ? moment.utc(moment(searchEndDate)).format('YYYY-MM-DDTHH:mm') : '';
        Apis.statisticSearch(currentPage, {searchUsername, startDate, endDate}).then((resp) => {
            setItemList(resp.data);
            setTotalData(resp.total);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e)
            // NotificationManager.error(e.response.data.error.message);
        })
    }

    return (
        <div>
            <Helmet>
                <title>정산관리</title>
                <meta name="description" content="user management"/>
            </Helmet>
            <div className='row justify-content-between'>
                <PageTitleBar
                    title="정산관리"
                />
            </div>
            <RctCollapsibleCard fullBlock contentCustomClasses={'p-50'}>
                <div className="table-responsive">
                    <div className="d-flex flex-row align-items-center">
                        <div className={'mr-4'} style={{width: 200}}>
                            <Input
                                type="text"
                                placeholder={'아이디를 입력하세요'}
                                value={searchUsername}
                                onChange={(e) => setSearchUsername(e.target.value)}
                            />
                        </div>
                        <div style={{width: 250}}>
                            <Input
                                type="date"
                                value={searchStartDate}
                                onChange={(e) => setSearchStartDate(e.target.value)}
                            />
                        </div>
                        <span className={'mx-2'}>~</span>
                        <div style={{width: 250}}>
                            <Input
                                type="date"
                                value={searchEndDate}
                                onChange={(e) => setSearchEndDate(e.target.value)}
                            />
                        </div>
                        <div className={'ml-4'}>
                            <Button variant={'contained'} color={'primary'} onClick={() => {currentPage === 1 ? getData() : setCurrentPage(1)}}>조회</Button>
                        </div>
                    </div>
                    <table className="table table-middle table-hover mb-0 mt-4">
                        <thead>
                        <tr style={{backgroundColor: '#baffe7'}}>
                            <td className={'text-center'}>합계</td>
                            <td className={'text-center'}>{totalData.total.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                            <td className={'text-center'}>{totalData.win.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                            <td className={'text-center'}>{totalData.lose.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                            <td className={'text-center'}>{(totalData.win - totalData.lose).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                        </tr>
                        <tr>
                            <th className={'text-center'}>아이디</th>
                            <th className={'text-center'}>총베팅금</th>
                            <th className={'text-center'}>수익금</th>
                            <th className={'text-center'}>손실금</th>
                            <th className={'text-center'}>이익금액</th>
                        </tr>
                        </thead>
                        <tbody>
                        {itemList && itemList.map((v, key) => (
                            <tr key={key}>
                                <td className={'text-center'}>{v.user?.username}</td>
                                <td className={'text-center'}>{v.total_bet_value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                                <td className={'text-center'}>{v.win_bet_value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                                <td className={'text-center'}>{v.lose_bet_value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                                <td className={'text-center'}>{(v.win_bet_value - v.lose_bet_value).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                <PaginationComponent
                                    totalItems={totalItemCount}
                                    pageSize={itemsPerPage}
                                    onSelect={(page) => setCurrentPage(page)}
                                    maxPaginationNumbers={7}
                                    activePage={currentPage}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </RctCollapsibleCard>
        </div>
    )
}

export default Statistic;