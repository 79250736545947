import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import AddAgentModal from './AddAgentModal';
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";


function AgentListModal({open, onClose}) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        if(open) getData();
    }, [open]);

    const getData = () => {
        Apis.agentList().then((resp) => {
            setItems(resp);
        })
    }

    const onAddAgent = (newAgentName) => {
        setShowAddModal(false);
        Apis.agentAdd(newAgentName).then((resp) => {
            NotificationManager.success('에이전트가 추가되였습니다')
            getData();
        }).catch((e) => {
        });
    }

    const onDelete = (id) => {
        Apis.agentDelete(id).then((resp) => {
            NotificationManager.success('에이전트가 삭제되었습니다.')
            getData();
        }).catch((e) => {
        });
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <div className={'d-flex flex-row justify-content-between'}>
                    <span>에이전트관리</span>
                    <Button variant={'contained'} size={'small'} color={'primary'} onClick={() => setShowAddModal(true)}>추가</Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <table className="table table-middle table-hover mb-0 ml-5 mr-5" style={{minWidth: 300}}>
                    <thead>
                    <tr>
                        <th>No</th>
                        <th className={'text-center'}>에이전트</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        items.map((v, i) => (
                            <tr key={v.id}>
                                <td>{i + 1}</td>
                                <td className={'text-center'}>{v.agent_name}</td>
                                <td>
                                    <button type="button" className="rct-link-btn" onClick={() => onDelete(v.id)}><i className="ti-close"/></button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </DialogContent>
            <AddAgentModal open={showAddModal} onClose={() => setShowAddModal(false)} onAdd={onAddAgent} />
        </Dialog>
    )
}

export default AgentListModal;