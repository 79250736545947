import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import {Input} from 'reactstrap';

function AddAgentModal({open, onClose, onAdd}) {
    const [agentText, setAgentText] = useState('');

    useEffect(() => {
        setAgentText('');
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                에이전트추가
            </DialogTitle>
            <DialogContent>
                <Input
                    type={'text'}
                    style={{width: 350}}
                    value={agentText}
                    onChange={(e) => setAgentText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" className="text-white btn-success" onClick={() => onAdd(agentText)}>추가</Button>
                <Button variant="contained" className="text-white btn-danger" onClick={onClose}>취소</Button>
            </DialogActions>
        </Dialog>
    )
}
export default AddAgentModal;