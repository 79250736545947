import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {IconButton, Icon, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, RadioGroup, Radio, FormControlLabel} from '@material-ui/core';
import {Form, FormGroup, Label, Input, Col} from 'reactstrap';
import {useParams, useHistory} from "react-router-dom";
import {NotificationManager} from 'react-notifications';
import FileSaver from 'file-saver';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import moment from 'moment';
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import UserListModal from './UserListModal';
import SiteAddModal from "./SiteAddModal";
import PatternAddModal from "./PatternAddModal";
import * as Apis from 'Api';


const EditGroup = (props) => {
    const history = useHistory();
    const groupId = useParams().group_id;
    const [name, setName] = useState('');
    const [errorName, setErrorName] = useState(false);
    const [patternList, setPatternList] = useState([]);
    const [min, setMin] = useState('1000');
    const [errorMin, setErrorMin] = useState(false);
    const [max, setMax] = useState('10000000');
    const [errorMax, setErrorMax] = useState(false);
    const [bettingRound, setBettingRound] = useState("55");
    const [errorBettingRound, setErrorBettingRound] = useState(false);
    const [singleBettingRound, setSingleBettingRound] = useState("0");
    const [errorSingleBettingRound, setErrorSingleBettingRound] = useState(false);
    const [users, setUsers] = useState([]);
    const [isChangeUser, setIsChangeUser] = useState(false);
    const [sites, setSites] = useState([]);
    const [isChangeSite, setIsChangeSite] = useState(false);
    const [notice, setNotice] = useState('');
    const [bottomNotice, setBottomNotice] = useState('');
    const [payStrategy, setPayStrategy] = useState({});
    const [isChangePay, setIsChangePay] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showUserListModal, setShowUserListModal] = useState(false);
    const [showAddSiteModal, setShowAddSiteModal] = useState(false);
    const [showAdminPassModal, setShowAdminPassModal] = useState(false);
    const [editPatternId, setEditPatternId] = useState(null);
    const [adminPass, setAdminPass] = useState('');

    useEffect(() => {
        if (groupId) getData();
    }, []);

    useEffect(() => {
        setAdminPass('');
    }, [showAdminPassModal]);

    const getData = () => {
        Apis.groupInfo(groupId).then((resp) => {
            setName(resp.name);
            setPatternList(resp.pattern ? resp.pattern.split(',') : []);
            setMin(resp.min);
            setMax(resp.max);
            setBettingRound(resp.betting_round);
            setSingleBettingRound(resp.single_betting_round);
            setUsers(resp.users || []);
            setSites(resp.sites ? resp.sites.map((v) => v.url) : []);
            setNotice(resp.notice || '');
            setBottomNotice(resp.bottom_notice || '');
            if (!resp.payStrategies) {
                resp.payStrategies = [];
            }
            const val = {};
            for (let i = 1; i <= 200; i++) {
                const temp = resp.payStrategies.find((v) => v.step === i);
                val[i] = temp ? temp.value : 0;
            }
            setPayStrategy(val);

            setIsChangeUser(false);
            setIsChangeSite(false);
            setIsChangePay(false);
            setLoading(false);
        }).catch((e) => {
            console.error(e);
            NotificationManager.error('오류가 발생했습니다.');
        });
    }

    const validation = () => {
        let result = true;
        if (name.length === 0) {
            result = false;
            setErrorName(true);
        }
        // if (patternList.length === 0) {
        //     result = false;
        //     NotificationManager.error('패턴을 추가해야 합니다.');
        // }

        if (min === '' || isNaN(min)) {
            result = false;
            setErrorMin(true);
        }
        if (max === '' || isNaN(max) || max <= min) {
            result = false;
            setErrorMax(true);
        }

        let matchResult = bettingRound.match(/[\d]+~?[\d]*/);
        if(matchResult === null || matchResult[0] !== bettingRound) {
            result = false;
            setErrorBettingRound(true);
        }

        matchResult = singleBettingRound.match(/[\d]+~?[\d]*/);
        if(matchResult === null || matchResult[0] !== singleBettingRound) {
            result = false;
            setErrorSingleBettingRound(true);
        }
        // Object.keys(payStrategy).forEach((step) => {
        //    if(min > payStrategy[step] || max < payStrategy[step]) result = false;
        // });
        return result;
    }

    const onSave = () => {
        if (validation()) {
            setShowAdminPassModal(true);
        }
    }

    const onSaveConfirm = () => {
        setShowAdminPassModal(false);
        if (!validation()) return
        const data = {
            name,
            pattern: patternList.length > 0 ? patternList.join(',') : undefined,
            notice,
            bottom_notice: bottomNotice,
            min,
            max,
            betting_round: bettingRound,
            single_betting_round: singleBettingRound,
            adminPass
        };
        if (isChangeUser) data.userIds = users.map((v) => v.id);
        if (isChangeSite) data.sites = sites;
        if (isChangePay) data.payStrategy = payStrategy;
        if (groupId) data.id = groupId;
        Apis.groupChange(data).then((resp) => {
            NotificationManager.success('저장되였습니다.')
            getData();
        }).catch((e) => {
            NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
        });
    }

    const onChangeUser = (item, index) => {
        setIsChangeUser(true);
        const newUsers = [...users];
        if (index === -1) {
            newUsers.push(item);
            setUsers(newUsers);
        } else {
            if (window.confirm('정말 삭제하시겠습니까?')) {
                newUsers.splice(index, 1);
                setUsers(newUsers);
            }
        }
    }

    const onChangeSite = (url, index) => {
        setIsChangeSite(true);
        const newSites = [...sites];
        if (index === -1) {
            newSites.push(url);
            setSites(newSites);
        } else {
            if (window.confirm('정말 삭제하시겠습니까?')) {
                newSites.splice(index, 1);
                setSites(newSites);
            }
        }
    }

    const onChangePattern = (p, index) => {
        const newPattenList = [...patternList];
        if (index === -1) {
            newPattenList.push(p);
            setPatternList(newPattenList);
        } else {
            if(p === null) {
                if (window.confirm('정말 삭제하시겠습니까?')) {
                    newPattenList.splice(index, 1);
                    setPatternList(newPattenList);
                }
            } else {
                newPattenList[index] = p;
                setPatternList(newPattenList);
            }
        }
    }

    const onChangePayStrategy = (key, value) => {
        setIsChangePay(true);
        payStrategy[key] = value;
        setPayStrategy({...payStrategy});
    }

    const onPayExport = () => {
        if (groupId) {
            Apis.groupPayStrategyExport(groupId).then(resp => {
                if (resp.length > 0) {
                    const byteCharacters = atob(resp);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    FileSaver.saveAs(blob, "금액.xlsx");
                } else {
                    NotificationManager.error('자료가 없습니다.')
                }
            }).catch((e) => {
                NotificationManager.error('오류가 발생했습니다.')
            });
        }

    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: `10px 0`,
        fontSize: 13,
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",
        borderBottom: '1px solid #dddddd',
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#eeeeee" : "white",
        padding: 0,
        // width: 250
    });

    const onSiteDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            sites,
            result.source.index,
            result.destination.index
        );
        setIsChangeSite(true);
        setSites(newItems);
    }

    const renderPay = () => {
        // const backgroundColor = phase % 2 ? '#eeeeee' : '#e0e0e0';
        return (
            <div className={'pay-grid-container'}>
                {
                    Object.keys(payStrategy).map((key) => (
                        <div className={'d-flex flex-column align-items-center pl-1 pr-1 pb-2'} style={{backgroundColor: '#eeeeee'}} key={key}>
                            <span>{key}회</span>
                            <Input
                                type="number"
                                value={payStrategy[key] || ''}
                                invalid={payStrategy[key] !== 0 && (min > payStrategy[key] || max < payStrategy[key])}
                                onChange={(e) => onChangePayStrategy(key, e.target.value)}
                            />
                        </div>
                    ))
                }
            </div>
        )
    }

    const renderSite = () => {
        return (
            <div>
                <div className={'row m-0 bg-light border-top border-bottom'} style={{padding: '10px 0'}}>
                    <div className={'col-1 text-center'}>No</div>
                    <div className={'col-9 text-center'}>URL</div>
                    <div className={'col-2 text-center'}>관리</div>
                </div>
                <DragDropContext onDragEnd={onSiteDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                {sites.map((item, index) => (
                                    <Draggable key={item} draggableId={item} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="row m-0"
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div className={'col-1 text-center'}>{index + 1}</div>
                                                <div className={'col-9 text-center'}>{item}</div>
                                                <div className={'col-2 text-center'}>
                                                    <div>
                                                        <IconButton className={'text-danger'} size={'small'} onClick={() => onChangeSite(item, index)}>
                                                            <Icon>delete_outline</Icon>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
        return (
            <table className="table table-middle table-hover mb-0">
                <thead>
                <tr>
                    <th className={'text-center'}>URL</th>
                    <th className={'text-center'}>관리</th>
                </tr>
                </thead>
                <tbody>
                {
                    sites.map((v, index) => (
                        <tr key={index}>
                            <td className={'text-center'}>{v}</td>
                            <td className={'text-center'}>
                                <IconButton className={'text-danger'} size={'small'} onClick={() => onChangeSite(v, index)}>
                                    <Icon>delete_outline</Icon>
                                </IconButton>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        )
    }

    return (
        <div>
            <Helmet>
                <title>그룹관리</title>
                <meta name="description" content="user management"/>
            </Helmet>
            <div className='row justify-content-between'>
                <PageTitleBar
                    title={<span><span>그룹관리</span> <i className="ti-angle-right fs-14"/> {groupId ? name : '추가'}</span>}
                    match={props.match}
                />

                <div className={'mr-20'}>
                </div>
            </div>
            <RctCollapsibleCard heading={'기본정보'} contentCustomClasses={'d-flex flex-direction-row'} onSave={onSave}>
                <FormGroup className={'mr-2'} style={{width: 150}}>
                    <Label for="name">그룹명</Label>
                    <Input
                        type="text"
                        className={'mt-0'}
                        value={name}
                        invalid={errorName}
                        onChange={(e) => {
                            setName(e.target.value);
                            setErrorName(false)
                        }}
                    />
                </FormGroup>
                <div className={'d-flex flex-direction-row ml-50'} style={{width: 350}}>
                    <FormGroup className={'ml-50'}>
                        <Label for="name">최소배팅금액</Label>
                        <Input
                            type="number"
                            value={min}
                            invalid={errorMin}
                            onChange={(e) => {
                                setMin(e.target.value);
                                setErrorMin(false)
                            }}
                        />
                    </FormGroup>
                    <span className={'ml-10 mr-10 mt-40'}>~</span>
                    <FormGroup className={''}>
                        <Label for="name">최대배팅금액</Label>
                        <Input
                            type="number"
                            value={max}
                            invalid={errorMax}
                            onChange={(e) => {
                                setMax(e.target.value);
                                setErrorMax(false);
                            }}
                        />
                    </FormGroup>
                </div>
                <div className={''}>
                    <FormGroup className={'ml-50'}>
                        <Label for="name">모든방 베팅회차설정</Label>
                        <Input
                            type="text"
                            invalid={errorBettingRound}
                            value={bettingRound}
                            onChange={(e) => {setBettingRound(e.target.value); setErrorBettingRound(false);}}
                        />
                    </FormGroup>
                </div>
                <div className={''}>
                    <FormGroup className={'ml-50'}>
                        <Label for="name">단독방 베팅회차설정</Label>
                        <Input
                            type="text"
                            invalid={errorSingleBettingRound}
                            value={singleBettingRound}
                            onChange={(e) => {setSingleBettingRound(e.target.value); setErrorSingleBettingRound(false)}}
                        />
                    </FormGroup>
                </div>
            </RctCollapsibleCard>
            <div className={'row'}>
                {/*<div className={'col'}>*/}
                {/*    <RctCollapsibleCard*/}
                {/*        heading={'패턴 List'} onAdd={() => setEditPatternId(-1)} colStyle={{paddingBottom: 23}}*/}
                {/*        customStyle={{height: '100%'}}*/}
                {/*        onSave={groupId ? onSave : undefined}*/}
                {/*    >*/}
                {/*        <table className="table table-middle table-hover mb-0">*/}
                {/*            <thead>*/}
                {/*            <tr>*/}
                {/*                <th className={'text-center'}>No</th>*/}
                {/*                <th className={'text-center'}>패턴</th>*/}
                {/*                <th className={'text-center'}>관리</th>*/}
                {/*            </tr>*/}
                {/*            </thead>*/}
                {/*            <tbody>*/}
                {/*            {*/}
                {/*                patternList.map((v, index) => (*/}
                {/*                    <tr key={index}>*/}
                {/*                        <td className={'text-center'}>{index + 1}</td>*/}
                {/*                        <td className={'text-center'}>{v}</td>*/}
                {/*                        <td className={'text-center'}>*/}
                {/*                            <IconButton className={'text-indigo '} size={'small'} onClick={() => setEditPatternId(index)}>*/}
                {/*                                <Icon>edit_outline</Icon>*/}
                {/*                            </IconButton>*/}
                {/*                            <IconButton className={'text-danger'} size={'small'} onClick={() => onChangePattern(null, index)}>*/}
                {/*                                <Icon>delete_outline</Icon>*/}
                {/*                            </IconButton>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                ))*/}
                {/*            }*/}
                {/*            </tbody>*/}
                {/*        </table>*/}
                {/*    </RctCollapsibleCard>*/}
                {/*</div>*/}
                <div className={'col'}>
                    <RctCollapsibleCard
                        heading={'회원 List'} onAdd={() => setShowUserListModal(true)} colStyle={{paddingBottom: 23}}
                        customStyle={{height: '100%'}}
                        onSave={groupId ? onSave : undefined}
                    >
                        <table className="table table-middle table-hover mb-0">
                            <thead>
                            <tr>
                                <th className={'text-center'}>No</th>
                                <th className={'text-center'}>아이디</th>
                                <th className={'text-center'}>종료일</th>
                                <th className={'text-center'}>관리</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                users.map((user, index) => (
                                    <tr key={index}>
                                        <td className={'text-center'}>{index + 1}</td>
                                        <td className={'text-center'}>{user.username}</td>
                                        <td className={'text-center'}>{moment(user.expire_date).format('YYYY-MM-DD')}</td>
                                        <td className={'text-center'}>
                                            <IconButton className={'text-danger'} size={'small'} onClick={() => onChangeUser(user, index)}>
                                                <Icon>delete_outline</Icon>
                                            </IconButton>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </RctCollapsibleCard>
                </div>
                <div>
                    {/*<RctCollapsibleCard*/}
                    {/*    heading={'Site list'} onAdd={() => setShowAddSiteModal(true)}*/}
                    {/*    onSave={groupId ? onSave : undefined}*/}
                    {/*>*/}
                    {/*    {renderSite()}*/}
                    {/*</RctCollapsibleCard>*/}
                    <div className={'d-flex'}>
                        <RctCollapsibleCard heading={'공지사항'} colClasses='mr-2' onSave={groupId ? onSave : undefined}>
                            <Input
                                type="textarea"
                                style={{width: 250}}
                                value={notice}
                                onChange={(e) => setNotice(e.target.value)}
                            />
                        </RctCollapsibleCard>
                        <RctCollapsibleCard heading={'하단공지사항'} onSave={groupId ? onSave : undefined}>
                            <Input
                                type="textarea"
                                style={{width: 250}}
                                value={bottomNotice}
                                onChange={(e) => setBottomNotice(e.target.value)}
                            />
                        </RctCollapsibleCard>
                    </div>
                </div>
            </div>
            <RctCollapsibleCard
                heading={
                    <div className={'d-flex flex-row'}>
                        <span>금액관리</span>
                        {
                            groupId &&
                            <Button variant={'outlined'} size={'small'} onClick={(e) => {
                                e.preventDefault();
                                onSave()
                            }} className={'ml-40'}>저장</Button>
                        }
                    </div>
                }
            >
                {renderPay()}
            </RctCollapsibleCard>

            <Dialog
                open={showAdminPassModal}
                onClose={() => setShowAdminPassModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">관리자확인</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        그룹정보를 변경하려면 관리자암호를 입력해야 합니다.
                    </DialogContentText>
                    <FormGroup row>
                        <Label for="userEmail" sm={4}>관리자암호</Label>
                        <Col sm={8}>
                            <Input
                                type="password"
                                value={adminPass}
                                onChange={(e) => setAdminPass(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowAdminPassModal(false)} className="btn-danger text-white">
                        취소
                    </Button>
                    <Button onClick={onSaveConfirm} className="btn-primary text-white" autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>

            {
                showUserListModal &&
                <UserListModal
                    selectedUsers={users}
                    handleChangeUsers={(item, index) => onChangeUser(item, index)}
                    onClose={() => setShowUserListModal(false)}
                />
            }
            {
                showAddSiteModal &&
                <SiteAddModal onAddSite={(url) => onChangeSite(url, -1)} onClose={() => setShowAddSiteModal(false)}/>
            }
            {
                editPatternId !== null &&
                <PatternAddModal
                    initPattern={editPatternId !== -1 ? patternList[editPatternId] : ''}
                    onAddPattern={(p) => onChangePattern(p, editPatternId)}
                    onClose={() => setEditPatternId(null)}
                />
            }
        </div>
    )
}

export default EditGroup;