/**
 * Rct Collapsible Card
 */
import React, {useState} from 'react';
import {Collapse, Badge} from 'reactstrap';
import {Button} from '@material-ui/core';
import classnames from 'classnames';
import RctSectionLoader from '../RctSectionLoader/RctSectionLoader';

function RctCollapsibleCard(props) {
    const [reload, setReload] = useState(false);
    const [collapse, setCollapse] = useState(true);
    const [close, setClose] = useState(false);

    const onReload = (e) => {
        e.preventDefault();
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 1500);
    }

    const {
        children, collapsible, closeable, reloadable, heading, fullBlock,
        colClasses, colStyle, customClasses, customStyle, headingCustomClasses,
        contentCustomClasses, badge, onAdd, onSave
    } = props;
    return (
        <div className={classnames(colClasses ? colClasses : '', {'d-block': !collapse})} style={colStyle || {}}>
            <div className={classnames(`rct-block ${customClasses ? customClasses : ''}`, {'d-none': close})} style={customStyle || {}}>
                {heading &&
                <div className={`rct-block-title ${headingCustomClasses ? headingCustomClasses : ''}`}>
                    <h4>{heading} {badge && <Badge className="p-1 ml-10" color={badge.class}>{badge.name}</Badge>}</h4>
                    {
                        (collapsible || reloadable || closeable || onAdd !== undefined || onSave) &&
                        <div className="contextual-link">
                            {
                                collapsible &&
                                <a href="!#"
                                   onClick={(e) => {
                                       e.preventDefault();
                                       setCollapse(!collapse)
                                   }}>
                                    <i className="ti-minus"/>
                                </a>
                            }
                            {
                                onAdd !== undefined && <Button variant={'outlined'} size={'small'} onClick={(e) => {e.preventDefault(); onAdd()}} className={'ml-2'}>추가</Button>
                            }
                            {
                                onSave !== undefined && <Button variant={'outlined'} size={'small'} onClick={(e) => {e.preventDefault(); onSave()}} className={'ml-2'}>저장</Button>
                            }
                            {
                                reloadable && <a href="!#" onClick={(e) => onReload(e)}><i className="ti-reload"/></a>
                            }
                            {
                                closeable &&
                                <a href="!#"
                                   onClick={(e) => {
                                       e.preventDefault();
                                       setClose(true)
                                   }}>
                                    <i className="ti-close"/>
                                </a>
                            }
                        </div>
                    }
                </div>
                }
                <Collapse isOpen={collapse}>
                    <div className={classnames(contentCustomClasses ? contentCustomClasses : '', {"rct-block-content": !fullBlock, 'rct-full-block': fullBlock})}>
                        {children}
                    </div>
                </Collapse>
                {reload && <RctSectionLoader/>}
            </div>
        </div>
    );
}

export default RctCollapsibleCard;
