// Agency nav links
let navlinks = {
   category1: [
      {
         "menu_title": "sidebar.dashboard",
         "menu_icon": "zmdi zmdi-view-dashboard",
         "new_item": true,
         "child_routes": [
            {
               "path": "/app/dashboard/ecommerce",
               "menu_title": "sidebar.ecommerce",
               "new_item": false,
               exact: true
            },
            {
               "path": "/dashboard/crm/dashboard",
               "new_item": true,
               "menu_title": "sidebar.crm",
               exact: true
            },
            {
               "path": "/horizontal/dashboard/saas",
               "menu_title": "sidebar.saas",
               "new_item": false,
               exact: true
            },
            {
               "path": "/agency/dashboard/agency",
               "menu_title": "sidebar.agency",
               "new_item": false,
               exact: true
            },
            {
               "path": "/boxed/dashboard/news",
               "menu_title": "sidebar.news",
               "new_item": false,
               exact: true
            }
         ]
      },
      {
         "menu_title": "sidebar.crm",
         "menu_icon": "zmdi zmdi-book",
         "new_item": true,
         "child_routes": [
            {
               "path": "/dashboard/crm/projects",
               "new_item": true,
               "menu_title": "sidebar.projects",
               exact: true
            },
            {
               "path": "/dashboard/crm/clients",
               "new_item": true,
               "menu_title": "sidebar.clients",
               exact: true
            },
            {
               "path": "/dashboard/crm/reports",
               "new_item": true,
               "menu_title": "sidebar.reports",
               exact: true
            }
         ]
      },
      {
         "menu_title": "sidebar.ecommerce",
         "menu_icon": "zmdi zmdi-shopping-cart",
         "new_item": false,
         "child_routes": [
            {
               "path": "/ecommerce/shop",
               "new_item": false,
               "menu_title": "sidebar.shop"
            },
            {
               "path": "/ecommerce/cart",
               "new_item": false,
               "menu_title": "sidebar.cart"
            },
            {
               "path": "/ecommerce/checkout",
               "new_item": false,
               "menu_title": "sidebar.checkout"
            },
            {
               "path": "/ecommerce/shop-list",
               "new_item": false,
               "menu_title": "sidebar.shopList"
            },
            {
               "path": "/ecommerce/shop-grid",
               "new_item": false,
               "menu_title": "sidebar.shopGrid"
            },
            {
               "path": "/ecommerce/invoice",
               "new_item": false,
               "menu_title": "sidebar.invoice"
            }
         ]
      },
      {
         "menu_title": "sidebar.widgets",
         "new_item": false,
         "menu_icon": "zmdi zmdi-widgets",
         "child_routes": [
            {
               "path": "/widgets/charts",
               "new_item": false,
               "menu_title": "sidebar.charts"
            },
            {
               "path": "/widgets/promo",
               "new_item": false,
               "menu_title": "sidebar.promo"
            },
            {
               "path": "/widgets/general",
               "new_item": false,
               "menu_title": "sidebar.general"
            },
            {
               "path": "/widgets/user",
               "new_item": false,
               "menu_title": "sidebar.user"
            },
         ]
      },
      {
         "menu_title": "sidebar.pages",
         "new_item": false,
         "menu_icon": "zmdi zmdi-file-plus",
         "child_routes": [
            {
               "path": "/pages/gallery",
               "new_item": false,
               "menu_title": "sidebar.gallery"
            },
            {
               "path": "/pages/pricing",
               "new_item": false,
               "menu_title": "sidebar.pricing"
            },
            {
               "path": "/pages/blank",
               "new_item": false,
               "menu_title": "sidebar.blank"
            },
            {
               "path": "/terms-condition",
               "new_item": false,
               "menu_title": "sidebar.terms&Conditions"
            },
            {
               "path": "/pages/feedback",
               "new_item": false,
               "menu_title": "sidebar.feedback"
            },
            {
               "path": "/pages/report",
               "new_item": false,
               "menu_title": "sidebar.report"
            },
            {
               "path": "/pages/faq",
               "new_item": false,
               "menu_title": "sidebar.faq(s)"
            }
         ]
      },
      {
         "menu_title": "sidebar.session",
         "menu_icon": "zmdi zmdi-time",
         "new_item": false,
         "child_routes": [
            {
               "path": "/session/login",
               "new_item": false,
               "menu_title": "sidebar.login",
               exact: true
            },
            {
               "path": "/session/register",
               "new_item": false,
               "menu_title": "sidebar.register",
               exact: true
            },
            {
               "path": "/session/lock-screen",
               "new_item": false,
               "menu_title": "sidebar.lockScreen",
               exact: true
            },
            {
               "path": "/session/forgot-password",
               "new_item": false,
               "menu_title": "sidebar.forgotPassword",
               exact: true
            },
            {
               "path": "/session/404",
               "new_item": false,
               "menu_title": "sidebar.404",
               exact: true
            },
            {
               "path": "/session/500",
               "new_item": false,
               "menu_title": "sidebar.500",
               exact: true
            }
         ]
      }
   ],
}

export default navlinks;