// sidebar nav links
let sidebarMenu = {
   categoryAdmin: [
      {
         "menu_title": "총판관리",
         "menu_icon": "zmdi zmdi-account",
         "path": "/app/admin/distributor",
         "child_routes": null
      },
      {
         "menu_title": "회원관리",
         "menu_icon": "zmdi zmdi-account",
         "path": "/app/admin/user",
         "child_routes": null
      },
      {
         "menu_title": "그룹관리",
         "menu_icon": "zmdi zmdi-accounts",
         "path": "/app/admin/group",
         "child_routes": null
      },
      {
         "menu_title": "게임내역",
         "menu_icon": "zmdi zmdi-rotate-left",
         "path": "/app/admin/log",
         "child_routes": null
      },
      {
         "menu_title": "정산관리",
         "menu_icon": "zmdi zmdi-money",
         "path": "/app/admin/statistic",
         "child_routes": null
      },
      {
         "menu_title": "업데이트관리",
         "menu_icon": "zmdi zmdi-upload",
         "path": "/app/admin/update",
         "child_routes": null
      },
      {
         "menu_title": "패턴리스트",
         "menu_icon": "zmdi zmdi-format-list-bulleted",
         "path": "/app/admin/pattern-list",
         "child_routes": null
      },
      {
         "menu_title": "소켓정보확인",
         "menu_icon": "zmdi zmdi-globe-alt",
         "path": "/app/admin/lobby-ws",
         "child_routes": null
      },
      {
         "menu_title": "매칭리스트",
         "menu_icon": "zmdi zmdi-globe-alt",
         "path": "/app/admin/matching-list",
         "child_routes": null
      },
   ],
}

export default sidebarMenu