import React, {useState, useEffect, useRef} from "react";
import {Helmet} from "react-helmet";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import {NavLink, useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import FileSaver from 'file-saver';
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import PaginationComponent from "Components/PaginationComponent";
import RctSectionLoader from "Components/RctSectionLoader/RctSectionLoader";
import EditPatternModal from "./EditPatternModal";
import DeleteConfirmationDialog from 'Components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import * as Apis from 'Api';

const PatternList = (props) => {
    const history = useHistory();
    const deleteConfirmationDialog = useRef();
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);


    useEffect(() => {
        getData();
    }, [currentPage]);

    const getData = () => {
        Apis.patternList(currentPage).then((resp) => {
            setLoading(false);
            setItemList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        })
    }

    const onReload = (e) => {
        e.preventDefault();
        getData();
    }

    const onChangePattern = (data) => {
        let func;
        if (editId === -1) {
            func = Apis.patternListAdd(data)
        } else {
            func = Apis.patternListUpdate(editId, data);
        }
        func.then((resp) => {
            getData();
        });
        setEditId(null);
    }

    const onDeleteItem = (id) => {
        deleteConfirmationDialog.current.open();
        setDeleteId(id);
    }

    const deleteUserPermanently = () => {
        deleteConfirmationDialog.current.close();
        Apis.patternListDelete(deleteId).then(() => {
            getData();
        })
        setDeleteId(null);
    }

    return (
        <div className="user-management">
            <Helmet>
                <title>패턴리스트</title>
                <meta name="description" content="user management"/>
            </Helmet>
            <PageTitleBar
                title={"패턴리스트"}
                match={props.match}
            />
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="!#" onClick={(e) => onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"/></a>
                        </div>
                        <div>
                            <a href="!#" onClick={(e) => {
                                e.preventDefault();
                                setEditId(-1);
                            }} className="btn-sm btn-outline-default mr-10 px-3">추가</a>
                        </div>
                    </div>
                    <table className="table table-middle table-hover mb-0">
                        <thead>
                        <tr>
                            <th className={'text-center'}>No</th>
                            <th className={'text-center'}>패턴명</th>
                            <th className={'text-center'} style={{width: 900}}>패턴</th>
                            <th>관리</th>
                        </tr>
                        </thead>
                        <tbody>
                        {itemList && itemList.map((item, key) => (
                            <tr key={key}>
                                <td className={'text-center'}>{((currentPage - 1) * itemsPerPage) + key + 1}</td>
                                <td className={'text-center'}>{item.name}</td>
                                <td className={'text-center'}><div className={'text-ellipsis'} style={{width: 900}}>{item.pattern}</div></td>
                                <td className="list-action">
                                    <button type="button" className="rct-link-btn" onClick={() => setEditId(item.id)}><i className="ti-pencil"/></button>
                                    <button type="button" className="rct-link-btn" onClick={() => onDeleteItem(item.id)}><i className="ti-close"/></button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                <PaginationComponent
                                    totalItems={totalItemCount}
                                    pageSize={itemsPerPage}
                                    onSelect={(page) => setCurrentPage(page)}
                                    maxPaginationNumbers={7}
                                    activePage={currentPage}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                <DeleteConfirmationDialog
                    ref={deleteConfirmationDialog}
                    title="정말 항목을 삭제하시겠습니까?"
                    message="이 작업은 되돌릴수 없습니다."
                    onConfirm={() => deleteUserPermanently()}
                />
                {
                    editId !== null &&
                    <EditPatternModal
                        data={editId !== -1 ? itemList.find((v) => v.id === editId) : {name: '', pattern: ''}}
                        onAddPattern={(data) => onChangePattern(data)}
                        onClose={() => setEditId(null)}
                    />
                }
                {loading &&
                    <RctSectionLoader/>
                }
            </RctCollapsibleCard>
        </div>
    );
}

export default PatternList;