import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import {Button} from '@material-ui/core';
import {Col, FormGroup, Input, Label} from "reactstrap";
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

const Update = (props) => {
    const [version, setVersion] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.versionInfo().then((resp) => {
            setVersion(resp.version);
        });
    }

    const onChangeFile = (event) => {
        const file = event.target.files[0];
        if(file.name.lastIndexOf('zip') !== file.name.length - 3) {
            NotificationManager.error('zip파일만 업로드할수 있습니다.')
            return;
        }
        setFile(event.target.files[0]);
    };

    const onSave = () => {
        const formData = new FormData();
        formData.append(file.name.split('.')[0], file);
        Apis.versionUpdate(version, formData, onUploadProgress).then((resp) => {
            NotificationManager.success('파일이 업로드되었습니다.');
        }).catch((e) => {

        })
    }

    const onUploadProgress = (percent) => {
        console.log(percent);
    }

    return (
        <div>
            <Helmet>
                <title>업데이트관리</title>
                <meta name="description" content="user management" />
            </Helmet>
            <PageTitleBar
                title={"업데이트관리"}
                match={props.match}
            />
            <RctCollapsibleCard>
                <div>
                    <FormGroup row>
                        <Label for="userEmail" sm={1}>버전</Label>
                        <Col sm={3}>
                            <Input
                                type="text"
                                value={version}
                                onChange={(e) => setVersion(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="userEmail" sm={1}>파일선택</Label>
                        <Col sm={3}>
                            <Input
                                type="file"
                                accept={'.zip'}
                                onChange={onChangeFile}
                            />
                        </Col>
                    </FormGroup>
                    <div>
                        <Button variant={'contained'} color={'primary'} onClick={onSave}>저장</Button>
                    </div>
                </div>
            </RctCollapsibleCard>
        </div>
    );
};

export default Update;