// routes
import Admin from 'Routes/admin';

let routes = [
   {
      path: 'admin',
      component: Admin
   },
]

export default routes;