/**
 * Add New User Form
 */
import React, {useState, useEffect} from 'react';
import {Form, FormGroup, Label, Input, Col} from 'reactstrap';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel} from '@material-ui/core';
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";


const EditForm = ({id, open, onClose}) => {
    const [username, setUsername] = useState('');
    const [allowEditPattern, setAllowEditPattern] = useState(false);
    const [allowEditUser, setAllowEditUser] = useState(false);
    const [userMemo, setUserMemo] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [adminPass, setAdminPass] = useState('');

    useEffect(() => {
        if (open && !!id) {
            getData();
        }
    }, [open]);

    const getData = () => {
        Apis.userInfo(id).then((resp) => {
            setUsername(resp.username);
            setAllowEditPattern(resp.allow_edit_pattern);
            setAllowEditUser(resp.allow_edit_user);
            setUserMemo(resp.userMemo);
        });
    }

    const onSubmit = () => {
        if (username === '') {
            NotificationManager.error('아이디를 입력해야 합니다.');
            return;
        }
        if ((!id && password === '') || password !== confirmPassword) {
            NotificationManager.error('암호를 정확히 입력해야 합니다.');
            return;
        }
        if (adminPass === '') {
            NotificationManager.error('관리자암호를 입력해야 합니다.');
            return;
        }
        let userInfo = {
            username: username,
            allow_edit_pattern: allowEditPattern,
            allow_edit_user: allowEditUser,
            password: password !== '' ? password : undefined,
            adminPassword: adminPass,
            userMemo,
        }
        let promise;
        if (!id) {
            userInfo.user_type = 'DISTRIBUTOR';
            promise = Apis.userAdd(userInfo)
        } else {
            promise = Apis.userUpdate(id, userInfo)
        }
        promise.then((resp) => {
            NotificationManager.success('저장되었습니다.');
            onClose();
        }).catch((e) => {
            NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
        });
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {id ? '총판변경' : '총판추가'}
            </DialogTitle>
            <DialogContent>
                <FormGroup className={'d-flex flex-row align-items-center'}>
                    <Label className={'mt-1'} style={{width: 70}}>아이디 </Label>
                    <div className={'ml-4'}>
                        <Input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                </FormGroup>
                <FormControlLabel
                    className={'ml-0'}
                    control={
                        <Checkbox
                            checked={allowEditUser}
                            onChange={() => setAllowEditUser(!allowEditUser)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="회원변경"
                    labelPlacement="start"
                />
                <FormControlLabel
                    className={'ml-4'}
                    control={
                        <Checkbox
                            checked={allowEditPattern}
                            onChange={() => setAllowEditPattern(!allowEditPattern)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="패턴변경"
                    labelPlacement="start"
                />
                <FormGroup className={'d-flex flex-row align-items-center'}>
                    <Label className={'mt-1'} style={{width: 70}}>메모 </Label>
                    <div className={'ml-4'}>
                        <Input
                            type="text"
                            value={userMemo}
                            onChange={(e) => setUserMemo(e.target.value)}
                        />
                    </div>
                </FormGroup>
                <FormGroup className={'d-flex flex-row align-items-center'}>
                    <Label className={'mt-1'} style={{width: 70}}>암호 </Label>
                    <div className={'ml-4'}>
                        <Input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </FormGroup>
                <FormGroup className={'d-flex flex-row align-items-center'}>
                    <Label className={'mt-1'} style={{width: 70}}>암호확인 </Label>
                    <div className={'ml-4'}>
                        <Input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                </FormGroup>
                <FormGroup className={'d-flex flex-row align-items-center'}>
                    <Label for="userName" className={'mt-1'} style={{width: 70}}>관리자암호 </Label>
                    <div className={'ml-4'}>
                        <Input
                            type="password"
                            value={adminPass}
                            onChange={(e) => setAdminPass(e.target.value)}
                        />
                    </div>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} size={'small'} color={'secondary'} onClick={onClose}>취소</Button>
                <Button variant={'contained'} size={'small'} color={'primary'} onClick={onSubmit}>{id ? '변경' : '추가'}</Button>
            </DialogActions>
        </Dialog>
    )
};

export default EditForm;
