/**
 * Add New User Form
 */
import React, {useState, useEffect} from 'react';
import {Form, FormGroup, Label, Input} from 'reactstrap';
import {IconButton, Icon} from '@material-ui/core';
import moment from 'moment';
import PatternListModal from "./PatternListModal";

const EditForm = ({item, groupList, agentList, onChangeItem}) => {
    const [showPatternModal, setShowPatternModal] = useState(false);

    return (
        <Form>
            <FormGroup>
                <Label>아이디</Label>
                <Input
                    type="text"
                    value={item.username}
                    onChange={(e) => onChangeItem('username', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="userEmail">암호</Label>
                <Input
                    type="password"
                    value={item.password}
                    onChange={(e) => onChangeItem('password', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="userEmail">암호확인</Label>
                <Input
                    type="password"
                    value={item.confirmPassword}
                    onChange={(e) => onChangeItem('confirmPassword', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label >패턴(B, P, - 만 포함되여야 합니다.)</Label>
                <div className={'d-flex flex-row'}>
                    <Input
                        type="text"
                        value={item.pattern}
                        disabled
                        // onChange={(e) => onChangeItem('pattern', e.target.value)}
                    />
                    <IconButton aria-label="add-pattern" className={'ml-1'} onClick={() => setShowPatternModal(true)}>
                        <Icon color="primary">add_circle</Icon>
                    </IconButton>
                </div>
            </FormGroup>
            <FormGroup>
                <Label for="userType">에이전트</Label>
                <Input
                    type="select"
                    value={item.agent_id}
                    onChange={(e) => onChangeItem('agent_id', e.target.value)}
                >
                    <option style={{display: 'none'}}>에이전트선택</option>
                    {
                        agentList.map((v) => <option value={v.id} key={v.id}>{v.agent_name}</option>)
                    }
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="userType">그룹명</Label>
                <Input
                    type="select"
                    value={item.group_id}
                    onChange={(e) => onChangeItem('group_id', e.target.value)}
                >
                    <option style={{display: 'none'}}>그룹선택</option>
                    {
                        groupList.map((v) => <option value={v.id} key={v.id}>{v.name}</option>)
                    }
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="accountType">종료일</Label>
                <Input
                    type="date"
                    value={moment(item.expire_date).format('YYYY-MM-DD')}
                    onChange={(e) => onChangeItem('expire_date', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="userEmail">관리자암호</Label>
                <Input
                    type="password"
                    value={item.adminPassword}
                    placeholder={'확인을 위해 관리자 암호를 입력하세요'}
                    onChange={(e) => onChangeItem('adminPassword', e.target.value)}
                />
            </FormGroup>
            <PatternListModal
                open={showPatternModal} pattern={item.pattern}
                onClose={() => setShowPatternModal(false)}
                onFinish={(pattern) => onChangeItem('pattern', pattern)}
            />
        </Form>
    )
};

export default EditForm;
