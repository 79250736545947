/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
import {Cookies} from 'react-cookie';
import {NotificationManager} from 'react-notifications';
import {
   LOGIN_USER,
   LOGIN_USER_SUCCESS,
   LOGIN_USER_FAILURE,
   LOGOUT_USER,
   SIGNUP_USER,
   SIGNUP_USER_SUCCESS,
   SIGNUP_USER_FAILURE
} from 'Store/Actions/types';
import * as Apis from 'Api';

const cookie = new Cookies();

/**
 * Redux Action To Sigin User With Firebase
 */
export const signinUser = (info, accessToken, history) => (dispatch) => {
   if (Apis.apiHost.indexOf(':') !== -1) {
      cookie.set('user_info', info, {path: '/'});
      cookie.set('access_token', accessToken, {path: '/'});
   }
   dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: {
         info: info,
         accessToken: accessToken,
      }
   });
   history.push('/');
}

/**
 * Redux Action To Signout User From  Firebase
 */
export const logoutUser = () => (dispatch) => {
   if (Apis.apiHost.indexOf(':') !== -1) {
      cookie.remove('user_info', {path: '/'});
      cookie.remove('access_token', {path: '/'});
   }
   dispatch({type: LOGOUT_USER});
   // NotificationManager.success('로그아웃되였습니다');
}