import React, {useState, useEffect, useRef} from "react";
import {Helmet} from "react-helmet";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import {NavLink, useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
import PaginationComponent from "Components/PaginationComponent";
import RctSectionLoader from "Components/RctSectionLoader/RctSectionLoader";
import DeleteConfirmationDialog from "../user/ConfirmAdminModal";
import * as Apis from 'Api';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "@material-ui/core/Button";

const Group = (props) => {
    const history = useHistory();
    const deleteConfirmationDialog = useRef();
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [newItem, setNewItem] = useState({name: '', pattern: ''});
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedCount, setSelectedCount] = useState(0);


    useEffect(() => {
        getData();
    }, [currentPage]);

    const getData = () => {
        Apis.groupList(currentPage).then((resp) => {
            setLoading(false);
            setItemList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        })
    }

    const onSelectAllItem = () => {
        let selectAll = selectedCount < itemList.length;
        if (selectAll) {
            let selectAllItems = itemList.map(item => {
                item.checked = true
                return item
            });
            setItemList(selectAllItems);
            setSelectedCount(selectAllItems.length);
        } else {
            let unselectedItems = itemList.map(item => {
                item.checked = false
                return item;
            });
            setSelectedCount(0);
            setItemList(unselectedItems);
        }
    }


    const onSelectItem = (item) => {
        item.checked = !item.checked;
        let selectedItemCount = 0;
        let allItems = itemList.map(v => {
            if (v.checked) {
                selectedItemCount++;
            }
            if (v.id === item.id) {
                if (v.checked) {
                    selectedItemCount++;
                }
                return item;
            } else {
                return v;
            }
        });
        setItemList(allItems);
        setSelectedCount(selectedItemCount);
    }

    const onDeleteItem = (data) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(data);
    }

    const deleteUserPermanently = (adminPassword) => {
        deleteConfirmationDialog.current.close();
        setLoading(true);
        Apis.groupDelete(selectedItem.id, adminPassword).then((resp) => {
            setLoading(false);
            setSelectedItem(null);
            getData();
            NotificationManager.success('삭제되였습니다.');
        }).catch((e) => {
            NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
        }).finally(() => {
            setLoading(false);
        });
    }

    const onReload = (e) => {
        e.preventDefault();
        getData();
    }


    return (
        <div className="user-management">
            <Helmet>
                <title>그룹관리</title>
                <meta name="description" content="user management" />
            </Helmet>
            <PageTitleBar
                title={"그룹관리"}
                match={props.match}
            />
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div>
                            <a href="!#" onClick={(e) => onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"/></a>
                        </div>
                        <div>
                            <NavLink to="/app/admin/group/new" color="primary" className="caret btn-sm mr-10">
                                추가 <i className="zmdi zmdi-plus"/>
                            </NavLink>
                        </div>
                    </div>
                    <table className="table table-middle table-hover mb-0">
                        <thead>
                        <tr>
                            <th className="w-5">
                                <FormControlLabel
                                    className={'mb-0'}
                                    control={
                                        <Checkbox
                                            indeterminate={selectedCount > 0 && selectedCount < itemList.length}
                                            checked={selectedCount > 0}
                                            onChange={(e) => onSelectAllItem(e)}
                                            value="all"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                            </th>
                            <th className={'text-center'}>그룹명</th>
                            <th className={'text-center'} style={{width: 150}}>패턴</th>
                            <th className={'text-center'}>회원수</th>
                            <th className={'text-center'}>시작일</th>
                            <th>관리</th>
                        </tr>
                        </thead>
                        <tbody>
                        {itemList && itemList.map((item, key) => (
                            <tr key={key}>
                                <td>
                                    <FormControlLabel
                                        label=''
                                        control={
                                            <Checkbox
                                                checked={!!item.checked}
                                                onChange={() => onSelectItem(item)}
                                                color="primary"
                                            />
                                        }
                                    />
                                </td>
                                <td className={'text-center'}>{item.name}</td>
                                <td className={'text-center'}>
                                    <div className={'text-ellipsis'} style={{width: 150}}>{item.pattern}</div>
                                </td>
                                <td className={'text-center'}>{item.userCount}</td>
                                <td className={'text-center'}>{moment(item.created_at).format('YYYY-MM-DD hh:mm:ss')}</td>
                                <td className="list-action">
                                    <button type="button" className="rct-link-btn" onClick={() => history.push('/app/admin/group/edit/' + item.id)}><i className="ti-pencil"/></button>
                                    <button type="button" className="rct-link-btn" onClick={() => onDeleteItem(item)}><i className="ti-close"/></button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                <PaginationComponent
                                    totalItems={totalItemCount}
                                    pageSize={itemsPerPage}
                                    onSelect={(page) => setCurrentPage(page)}
                                    maxPaginationNumbers={7}
                                    activePage={currentPage}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                {loading &&
                <RctSectionLoader />
                }
            </RctCollapsibleCard>
            <DeleteConfirmationDialog
                ref={deleteConfirmationDialog}
                title="정말 항목을 삭제하시겠습니까?"
                message="이 작업은 되돌릴수 없습니다."
                onConfirm={(password) => deleteUserPermanently(password)}
            />
        </div>
    );
}

export default Group;