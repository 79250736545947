import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Icon} from '@material-ui/core';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import PatternAddModal from "../group/PatternAddModal";
import PatternTemplateModal from "./PatternTemplateModal";

function PatternListModal({open, onClose, onFinish, pattern}) {
    const [patternList, setPatternList] = useState([]);
    const [editPatternId, setEditPatternId] = useState(null);
    const [showTemplateModal, setShowTemplateModal] = useState(false);

    useEffect(() => {
        setPatternList(pattern ? pattern.split(',') : []);
    }, [open]);

    const onChangePattern = (p, index) => {
        const newPattenList = [...patternList];
        if (index === -1) {
            newPattenList.push(p);
            setPatternList(newPattenList);
        } else {
            if (p === null) {
                if (window.confirm('정말 삭제하시겠습니까?')) {
                    newPattenList.splice(index, 1);
                    setPatternList(newPattenList);
                }
            } else {
                newPattenList[index] = p;
                setPatternList(newPattenList);
            }
        }
    }

    const onAddTemplate = (p) => {
        if(!p) return;
        setPatternList(patternList.concat(p.split(',')));
    }

    const onSubmit = () => {
        onFinish(patternList.join(','));
        onClose();
    }


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: `10px 0`,
        fontSize: 13,
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",
        borderBottom: '1px solid #dddddd',
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#eeeeee" : "white",
        padding: 0,
    });

    const onSiteDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            patternList,
            result.source.index,
            result.destination.index
        );
        setPatternList(newItems);
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className={'pb-0'}>
                <div className={'d-flex flex-row justify-content-between'}>
                    <span>패턴리스트</span>
                    <div className={'d-flex flex-row align-items-center'}>
                        <Button variant={'outlined'} size='small' onClick={() => setShowTemplateModal(true)}>템플릿추가</Button>
                        <IconButton aria-label="add-pattern" className={'ml-3'} size={'small'} onClick={() => setEditPatternId(-1)}>
                            <Icon color="primary">add_circle</Icon>
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{minWidth: 500}}>
                    <div className={'row m-0 bg-light border-top border-bottom'} style={{padding: '10px 0'}}>
                        <div className={'col-1 text-center'}>No</div>
                        <div className={'col-9 text-center'}>패턴</div>
                        <div className={'col-2 text-center'}>관리</div>
                    </div>
                    <DragDropContext onDragEnd={onSiteDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                    {patternList.map((item, index) => (
                                        <Draggable key={item} draggableId={item} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="row m-0"
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div className={'col-1 text-center'}>{index + 1}</div>
                                                    <div className={'col-9 text-center'}>{item}</div>
                                                    <div className={'col-2 text-center'}>
                                                        <div className={'d-flex flex-row'}>
                                                            <IconButton className={'text-indigo '} size={'small'} onClick={() => setEditPatternId(index)}>
                                                                <Icon>edit_outline</Icon>
                                                            </IconButton>
                                                            <IconButton className={'text-danger'} size={'small'} onClick={() => onChangePattern(null, index)}>
                                                                <Icon>delete_outline</Icon>
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant={'contained'}>
                    취소
                </Button>
                <Button onClick={onSubmit} color="primary" variant={'contained'}>
                    확인
                </Button>
            </DialogActions>
            {
                editPatternId !== null &&
                <PatternAddModal
                    initPattern={editPatternId !== -1 ? patternList[editPatternId] : ''}
                    onAddPattern={(p) => onChangePattern(p, editPatternId)}
                    onClose={() => setEditPatternId(null)}
                />
            }
            {
                showTemplateModal &&
                <PatternTemplateModal
                    open={showTemplateModal}
                    onClose={() => setShowTemplateModal(false)}
                    onAddPattern={(p) => onAddTemplate(p)}
                />
            }
        </Dialog>
    );
}

export default PatternListModal;