import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';
import {Col, FormGroup, Input, Label} from 'reactstrap';

const EditSiteModal = ({onClose, onAddSite, data}) => {
    const [site, setSite] = useState(data.site);

    const onSubmit = () => {
        if(site === '') return;
        onAddSite({site});
        onClose();
    }

    return (
        <Dialog open={true} onClose={onClose} maxWidth={'lg'}>
            <DialogTitle>사이트</DialogTitle>
            <DialogContent>
                <FormGroup row>
                    <Label for="userEmail" sm={3}>사이트</Label>
                    <Col sm={9}>
                        <Input
                            type={'text'}
                            value={site}
                            onChange={(e) => setSite(e.target.value)}
                        />
                    </Col>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant={'contained'}>
                    취소
                </Button>
                <Button onClick={onSubmit} color="primary" variant={'contained'}>
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditSiteModal;