import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, Button} from '@material-ui/core';
import moment from 'moment';
import PaginationComponent from "Components/PaginationComponent";
import RctSectionLoader from "Components/RctSectionLoader/RctSectionLoader";
import * as Apis from "Api";

const UserListModal = ({selectedUsers, handleChangeUsers, onClose}) => {

    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    }, [currentPage]);

    const getData = () => {
        Apis.userList(currentPage, true, {}).then((resp) => {
            setItems(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
            setLoading(false);
        }).catch((e) => {
            console.error(e);
        })
    }

    const renderItem = (item) => {
        let index = selectedUsers.findIndex((v) => v.id === item.id);
        return (
            <tr key={item.id} style={index < 0 ? {} : {backgroundColor: '#ffddb0'}}>
                <td>{item.username}</td>
                <td>{moment(item.expire_date).format('YYYY/MM/DD')}</td>
                <td>
                    <Button variant="contained" className={index < 0 ? "btn-primary text-white" : "btn-danger text-white"} size="small"
                            onClick={() => handleChangeUsers(item, index)}>{index < 0 ? "Add" : "Remove"}</Button>
                </td>
            </tr>
        )
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>회원선택</DialogTitle>
            <DialogContent>
                <table className="table table-middle table-hover mb-0 ml-5 mr-5">
                    <thead>
                    <tr>
                        <th>아이디</th>
                        <th>종료일</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading ?
                            <tr>
                                <td><RctSectionLoader/></td>
                            </tr> :
                            items.map((item) => (
                                renderItem(item)
                            ))
                    }
                    </tbody>
                    <tfoot className="border-top">
                    <tr>
                        <td colSpan="100%">
                            <PaginationComponent
                                totalItems={totalItemCount}
                                pageSize={itemsPerPage}
                                onSelect={(page) => setCurrentPage(page)}
                                maxPaginationNumbers={5}
                                activePage={currentPage}
                            />
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </DialogContent>
        </Dialog>
    )
}

export default UserListModal;