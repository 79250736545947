import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookie = new Cookies();

function getApiHost()
{
   let hostname = window.location.hostname;
   if (
       hostname === 'localhost' ||
       /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(hostname)
   )
   {
      hostname = hostname + ':5000';
   }
   return window.location.protocol + '//' + hostname;
}

export const apiHost = getApiHost();

export const apiAddress = apiHost + '/api/';

export const accessToken = () => cookie.get("access_token");

const instance = axios.create({
   baseURL: apiAddress,
   timeout: 40000
});

instance.interceptors.request.use(function (config) {
   const token = cookie.get("access_token");
   config.headers.Authorization =  token ? `Bearer ${token}` : '';
   return config;
});

instance.interceptors.response.use(response => {
   return response;
}, error => {
   if (error.response.status === 401 && error.request.responseURL.indexOf('api/users/login') === -1) {
      // logout status
      cookie.remove('user_info', {path: '/'});
      cookie.remove('access_token', {path: '/'});
      window.location.href = '/signin';
   }
   throw error;
});

export function login(username, password) {
   const url = '/users/login';
   const req = {
      username,
      password,
      login_type: 'admin',
   };
   return instance.post(url, req).then((response) => response.data);
}

export function logout() {
   const url = '/users/logout';
   return instance.get(url).then((response) => response.data);
}

export function changePassword(oldPassword, newPassword) {
   const url = '/users/password';
   return instance.post(url, {oldPassword, newPassword}).then((response) => response.data);
}

/**
 * user functions
 */
export function userList(page, noGroup, search) {
   let url = '/users?page=' + page + '&search=' + JSON.stringify(search);
   if(noGroup) {
      url += '&noGroup=1';
   }
   return instance.get(url).then((response) => response.data);
}

export function userInfo(id) {
   const url = `/users/${id}`;
   return instance.get(url).then((response) => response.data);
}

export function userAdd(data) {
   const url = '/users';
   return instance.post(url, data).then((response) => response.data);
}

export function userUpdate(id, data) {
   const url = '/users/' + id;
   return instance.patch(url, data).then((response) => response.data);
}

export function userPassword(userId, newPassword) {
   const url = '/users/change-password';
   return instance.post(url, {userId, password: newPassword}).then((response) => response.data);
}

export function userActive(userId) {
   const url = '/users/active';
   return instance.post(url, {userId}).then((response) => response.data);
}

export function userAvailablePattern(userId) {
   const url = '/users/availablePattern';
   return instance.post(url, {userId}).then((response) => response.data);
}

export function userDelete(userId, adminPassword) {
   const url = `/users/${userId}?adminPassword=${encodeURIComponent(adminPassword)}`;
   return instance.delete(url).then((response) => response.data);
}

export function userPatternUpdate(id, pattern) {
   const url = `/users/${id}/pattern`;
   return instance.post(url, pattern).then((response) => response.data);
}

export function userExport(type) {
   const url = `/users/export?type=${type}`;
   return instance.get(url).then((response) => response.data);
}

export function distributorExport() {
   const url = `/users/distributor-export`;
   return instance.get(url).then((response) => response.data);
}

export function userDistributorList(search, page) {
   let url = `/users/distributor?search=${JSON.stringify(search)}`;
   if(page) {
      url += `&page=${page}`;
   }
   return instance.get(url).then((response) => response.data);
}

export function userSiteUpdate(id, sites){
   const url = `/users/${id}/sites`;
   return instance.post(url, {sites}).then((response) => response.data);
}

/**
 * group functions
 */
export function groupList(page) {
   let url = '/groups';
   if(page) url += `?page=${page}`;
   return instance.get(url).then((response) => response.data);
}

export function groupChange(data) {
   const url = '/groups';
   return instance.post(url, data).then((response) => response.data);
}

export function groupUpdate(id, data) {
   const url = '/groups/' + id;
   return instance.patch(url, data).then((response) => response.data);
}

export function groupInfo(id) {
   const url = '/groups/' + id;
   return instance.get(url).then((response) => response.data);
}

export function groupDelete(id, adminPassword) {
   const url = `/groups/${id}?adminPassword=${encodeURIComponent(adminPassword)}`;
   return instance.delete(url).then((response) => response.data);
}

export function groupPayStrategyExport(id) {
   const url = `/groups/${id}/payStrategy`;
   return instance.get(url).then((response) => response.data);
}


/**
 * log functions
 */
export function logList(page, search) {
   let url = `/logs?page=${page}&search=${JSON.stringify(search)}`;
   return instance.get(url).then((response) => response.data);
}

export function logExport(search) {
   let url = `/logs/export?search=${JSON.stringify(search)}`;
   return instance.get(url).then((response) => response.data);
}

export function logRemoveAll() {
   let url = `/logs/all-remove`;
   return instance.get(url).then((response) => response.data);
}

/**
 * account functions
 */
export function accountList(page) {
   let url = `/account-lists?page=${page}`;
   return instance.get(url).then((response) => response.data);
}

export function accountExport() {
   let url = `/account-lists/export`;
   return instance.get(url).then((response) => response.data);
}

/**
 * setting functions
 */
export function versionUpdate(version, data, onProgress) {
   const url = `/settings/version?version=${version}`;
   const config = {
      timeout: 0,
      headers: {
         'content-type': 'multipart/form-data'
      },
      onUploadProgress: function (progressEvent) {
         let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
         onProgress(percent);
      }
   };
   return instance.post(url, data, config).then((response) => response.data);
}

export function versionInfo() {
   let url = `/settings/version`;
   return instance.get(url).then((response) => response.data);
}

/**
 * agent functions
 */
export function agentList() {
   let url = `/agents`;
   return instance.get(url).then((response) => response.data);
}

export function agentAdd(name) {
   let url = `/agents`;
   return instance.post(url, {name}).then((response) => response.data);
}

export function agentDelete(id) {
   let url = `/agents/${id}`;
   return instance.delete(url).then((response) => response.data);
}

/**
 * pattern list function
 */
export function patternList(page) {
   let url = `/pattern-lists`;
   if(page) {
      url += `?page=${page}`;
   }
   return instance.get(url).then((response) => response.data);
}

export function patternListAdd(data) {
   let url = `/pattern-lists`;
   return instance.post(url, data).then((response) => response.data);
}

export function patternListUpdate(id, data) {
   let url = `/pattern-lists/${id}`;
   return instance.patch(url, data).then((response) => response.data);
}

export function patternListDelete(id) {
   let url = `/pattern-lists/${id}`;
   return instance.delete(url).then((response) => response.data);
}

/**
 * statistic
 */
export function statisticSearch(page, search) {
   let url = `/statistics/search?page=${page}`;
   return instance.post(url, search).then((response) => response.data);
}

/**
 * notices
 */
export function noticeSend(userIds, text) {
   let url = `/notices`;
   return instance.post(url, {userIds, text}).then((response) => response.data);
}

export function restartRobby() {
   let url = '/settings/restart-lobby';
   return instance.get(url).then((response) => response.data);
}

/**
 * matching list function
 */
export function matchingList(page) {
   let url = `/matching-lists`;
   if(page) {
      url += `?page=${page}`;
   }
   return instance.get(url).then((response) => response.data);
}

export function matchingListAdd(data) {
   let url = `/matching-lists`;
   return instance.post(url, data).then((response) => response.data);
}

export function matchingListUpdate(id, data) {
   let url = `/matching-lists/${id}`;
   return instance.patch(url, data).then((response) => response.data);
}

export function matchingListDelete(id) {
   let url = `/matching-lists/${id}`;
   return instance.delete(url).then((response) => response.data);
}
