/**
 * Admin Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import User from './user';
import Group from './group';
import groupEditComponent from './group/Edit'
import Log from './log';
import Setting from './setting';
import AccountList from './account-list';
import Update from './update';
import PatternList from './pattern-list';
import Statistic from'./statistic';
import Distributor from "./distributor";
import UserEdit from "./user/UserEdit";
import LobbyWs from './lobby-ws';
import MatchingList from './matching-list';

const Admin = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}`} to={`${match.url}user`} />
            <Route path={`${match.url}/user/new`} component={UserEdit} />
            <Route path={`${match.url}/user/edit/:user_id`} component={UserEdit} />
            <Route path={`${match.url}/user`} component={User} />

            <Route path={`${match.url}/group/new`} component={groupEditComponent} />
            <Route path={`${match.url}/group/edit/:group_id`} component={groupEditComponent} />
            <Route path={`${match.url}/group`} component={Group} />

            <Route path={`${match.url}/log`} component={Log} />

            <Route path={`${match.url}/setting`} component={Setting} />
            <Route path={`${match.url}/account-list`} component={AccountList} />

            <Route path={`${match.url}/statistic`} component={Statistic} />

            <Route path={`${match.url}/update`} component={Update} />

            <Route path={`${match.url}/pattern-list`} component={PatternList} />

            <Route path={`${match.url}/distributor`} component={Distributor} />

            <Route path={`${match.url}/lobby-ws`} component={LobbyWs} />

            <Route path={`${match.url}/matching-list`} component={MatchingList} />
        </Switch>
    </div>
);

export default Admin;