/**
 * App.js Layout Start Here
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import RctThemeProvider from './RctThemeProvider';
import RctDefaultLayout from './DefaultLayout';
import CRMLayout from './CRMLayout';
import AppSignIn from './Signin';
import AppSignUp from './Signup';
/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const PrivateRoute = ({ component: Component, ...rest }) =>{
    const authUser = useSelector(state => state.authUser);
    const {isLogin} = authUser;
    return (
        <Route
            {...rest}
            render={props =>
                isLogin
                    ? <Component {...props} />
                    : <Redirect
                        to={{
                            pathname: '/signin',
                            state: {from: props.location}
                        }}
                    />}
        />
    );
}

function App(props) {
   const authUser = useSelector(state => state.authUser);
   const { user } = authUser;
   const { location, match } = props;
   if (location.pathname === '/') {
      if (user === null) {
         return (<Redirect to={'/signin'} />);
      } else {
         return (<Redirect to={'/app/admin/'} />);
      }
   }
   return (
      <RctThemeProvider>
         <NotificationContainer />
         <PrivateRoute
            path={`${match.url}app`}
            component={RctDefaultLayout}
         />
         <Route path="/dashboard" component={CRMLayout} />
         <Route path="/signin" component={AppSignIn} />
         <Route path="/signup" component={AppSignUp} />
      </RctThemeProvider>
   );
}

export default App;
