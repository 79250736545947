/**
 * User Management Page
 */
import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from "react-helmet";
import { Button, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import {Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap';
import {NotificationManager} from 'react-notifications';
import FileSaver from 'file-saver';
import moment from 'moment';
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';
import PaginationComponent from "Components/PaginationComponent";
import EditForm from './EditForm';
import DeleteConfirmationDialog from './ConfirmAdminModal';
import AgentListModal from "./AgentListModal";
import * as Apis from 'Api';
import PatternListModal from "./PatternListModal";
import {useHistory, useLocation} from "react-router-dom";

export default function User(props) {
    const history = useHistory();
    const getQuery = new URLSearchParams(useLocation().search);
    const deleteConfirmationDialog = useRef();
    const [users, setUsers] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [groupList, setGroupList] = useState([]);
    const [searchUserName, setSearchUserName] = useState('');

    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [addNewUserModal, setAddNewUserModal] = useState(false);
    const [addNewUserDetail, setAddNewUserDetail] = useState({username: '', password: '', pattern: '', group_id: '', agent_id: '', expire_date: ''});
    const [editUser, setEditUser] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState(0);
    const [showConfirmAdminModal, setShowConfirmAdminModal] = useState(true);
    const [agentList, setAgentList] = useState([]);
    const [showAddAgentModal, setShowAddAgentModal] = useState(false);
    const [agentText, setAgentText] = useState('');
    const [searchAgent, setSearchAgent] = useState('');
    const [showPatternModal, setShowPatternModal] = useState(false);
    const [distributorList, setDistributorList] = useState([]);
    const [searchDistributor, setSearchDistributor] = useState(getQuery.get('distributor'));

    useEffect(() => {
        Apis.groupList().then((resp) => {
            setGroupList(resp);
        });
        Apis.userDistributorList({}).then(resp => {
            setDistributorList(resp);
        });
    }, [])

    useEffect(() => {
        getData();
        getAgentList();
    }, [currentPage, searchAgent])

    useEffect(() => {
        setAgentText('');
    }, [showAddAgentModal])

    const getData = () => {
        const search = {
            agentId: searchAgent,
            distributorId: searchDistributor,
            searchUserName,
        }
        Apis.userList(currentPage, false, search).then((resp) => {
            setUsers(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getAgentList = () => {
        Apis.agentList().then((resp) => {
            setAgentList(resp);
        });
    }

    /**
     * On Delete
     */
    const onDelete = (data) => {
        deleteConfirmationDialog.current.open();
        setSelectedUser(data);
    }

    /**
     * Delete User Permanently
     */
    const deleteUserPermanently = (adminPassword) => {
        deleteConfirmationDialog.current.close();
        setLoading(true);
        Apis.userDelete(selectedUser.id, adminPassword).then(() => {
            NotificationManager.success('삭제되였습니다');
            getData();
        }).catch((e) => {
            NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
        }).finally(() => {
            setLoading(false);
        });
    }

    /**
     * Open Add New User Modal
     */
    const onAddNewUserModal = (e) => {
        e.preventDefault();
        setAddNewUserModal(true);
    }

    /**
     * On Reload
     */
    const onReload = (e) => {
        e.preventDefault();
        setLoading(true);
        getData();
    }

    /**
     * On Select User
     */
    const onSelectUser = (user) => {
        user.checked = !user.checked;
        let selectedUsers = 0;
        let allUsers = users.map(userData => {
            if (userData.checked) {
                selectedUsers++;
            }
            if (userData.id === user.id) {
                if (userData.checked) {
                    selectedUsers++;
                }
                return user;
            } else {
                return userData;
            }
        });
        setUsers(allUsers);
        setSelectedUser(selectedUsers);
    }

    /**
     * On Change Add New User Details
     */
    const onChangeAddNewUserDetails = (key, value) => {
        setAddNewUserDetail({
            ...addNewUserDetail,
            [key]: value
        })
    }

    /**
     * Add New User
     */
    const addNewUser = () => {
        const {username, password, confirmPassword, pattern, group_id, agent_id, expire_date, adminPassword} = addNewUserDetail;

        if (username !== '' && password !== '' && password === confirmPassword && group_id !== '' && expire_date !== '') {
            if (!!pattern && pattern !== '') {
                const patternMatch = pattern.match(/[BP\-,]+/);
                if (patternMatch === null || patternMatch[0] !== pattern) {
                    NotificationManager.error('패턴이 정확하지 않습니다.');
                    return;
                }
            }

            let newUser = {
                username,
                password,
                pattern,
                group_id,
                agent_id,
                expire_date,
                adminPassword
            }
            onAddUpdateUserModalClose();
            setLoading(true);
            Apis.userAdd(newUser).then((resp) => {
                NotificationManager.success('사용자가 추가되였습니다.');
            }).catch((e) => {
                NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
            }).finally(() => {
                setLoading(false);
                getData();
            });
        } else {
            NotificationManager.error('정보가 정확하지 않습니다.');
        }
    }

    const onUserUpdate = () => {
        const {username, password, confirmPassword, pattern, group_id, agent_id, expire_date, adminPassword} = editUser;
        if (username !== '' && group_id !== '' && expire_date !== '') {
            if (!!pattern && pattern !== '') {
                const patternMatch = pattern.match(/[BP\-,]+/);
                if (patternMatch === null || patternMatch[0] !== pattern) {
                    NotificationManager.error('패턴이 정확하지 않습니다.');
                    return;
                }
            }
            let userInfo = {
                username,
                pattern,
                group_id,
                agent_id,
                expire_date,
                adminPassword,
            }
            if (password !== undefined && password !== confirmPassword) {
                NotificationManager.error('암호가 일치하지 않습니다.');
                return;
            }
            if (password) {
                userInfo.password = password;
            }
            onAddUpdateUserModalClose();
            setLoading(true);
            Apis.userUpdate(editUser.id, userInfo).then((resp) => {
                NotificationManager.success('사용자가 변경되였습니다.');
            }).catch((e) => {
                NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
            }).finally(() => {
                setLoading(false);
                getData();
            });
        } else {
            NotificationManager.error('정보가 정확하지 않습니다.');
        }
    }

    /**
     * On Edit User
     */
    const onEditUser = (user) => {
        setAddNewUserModal(true);
        setEditUser(user);
    }

    /**
     * On Add & Update User Modal Close
     */
    const onAddUpdateUserModalClose = () => {
        setAddNewUserModal(false);
        setAddNewUserDetail({username: '', password: '', pattern: '', group_id: '', agent_id: '', expire_date: ''});
        setEditUser(null);
    }

    /**
     * On Update User Details
     */
    const onUpdateUserDetails = (key, value) => {
        setEditUser({
            ...editUser,
            [key]: value
        })
    }

    //Select All user
    const onSelectAllUser = (e) => {
        let selectAll = selectedUsers < users.length;
        if (selectAll) {
            let selectAllUsers = users.map(user => {
                user.checked = true
                return user
            });
            setUsers(selectAllUsers);
            setSelectedUsers(selectAllUsers.length);
        } else {
            let unselectedUsers = users.map(user => {
                user.checked = false
                return user;
            });
            setSelectedUsers(0);
            setUsers(unselectedUsers);
        }
    }

    const onToggleActiveUser = (user) => {
        Apis.userActive(user.id, !user.active).then((resp) => {
            if (user.active) {
                NotificationManager.success('회원이 사용중지 되였습니다.');
            } else {
                NotificationManager.success('회원이 활성화 되였습니다.');
            }
            getData();
        }).catch((e) => {
            NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
        });
    }

    const onToggleAvailablePattern = (user) => {
        Apis.userAvailablePattern(user.id).then((resp) => {
            NotificationManager.success('서식가능상태가 변경되었습니다.');
            getData();
        }).catch((e) => {
            NotificationManager.error(e.response ? e.response.data.error.message : '오류가 발생했습니다.');
        });
    }

    const onExport = () => {
        Apis.userExport('user').then((resp) => {
            if (resp.length > 0) {
                const byteCharacters = atob(resp);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(blob, "회원리스트.xlsx");
            } else {
                NotificationManager.error('자료가 없습니다.')
            }
        })
    }

    const onAddAgent = () => {
        const newAgentName = agentText;
        setShowAddAgentModal(false);
        Apis.agentAdd(newAgentName).then((resp) => {
            NotificationManager.success('에이전트가 추가되였습니다')
            getAgentList();
        }).catch((e) => {
        });
    }

    const onEditUserPattern = (user) => {
        setShowPatternModal(true);
        setEditUser(user);
    }

    const onUpdateUserPattern = (pattern) => {
        Apis.userPatternUpdate(editUser.id, {pattern}).then((resp) => {
            getData();
        });
        setShowPatternModal(false);
        setEditUser(null);
    }

    return (
        <div className="user-management">
            <Helmet>
                <title>회원관리</title>
                <meta name="description" content="user management"/>
            </Helmet>
            <PageTitleBar
                title={"회원관리"}
                match={props.match}
            />
            <RctCollapsibleCard fullBlock>
                <div className="table-responsive">
                    <div className="d-flex justify-content-between py-20 px-10 border-bottom">
                        <div className={'d-flex flex-row align-items-center'}>
                            <a href="!#" onClick={(e) => onReload(e)} className="btn-outline-default mr-10"><i className="ti-reload"/></a>
                            <Input type={'select'} value={searchDistributor} onChange={(e) => setSearchDistributor(e.target.value)}>
                                <option value={''}>총판</option>
                                {
                                    distributorList.map((v) => (
                                        <option value={v.id} key={v.id}>{v.username}</option>
                                    ))
                                }
                            </Input>
                            <Input type={'select'} value={searchAgent} className={'ml-2'} onChange={(e) => setSearchAgent(e.target.value)}>
                                <option value={''}>모든 에이전트</option>
                                {
                                    agentList.map((v) => (
                                        <option value={v.id} key={v.id}>{v.agent_name}</option>
                                    ))
                                }
                            </Input>
                            <Input className={'ml-2'} type={'text'} placeholder={'아이디'} value={searchUserName} onChange={(e) => setSearchUserName(e.target.value)} />
                            <Button onClick={getData} color="secondary" variant='contained' className="ml-2 p-1">검색</Button>
                        </div>
                        <div>
                            <Button onClick={(e) => setShowAddAgentModal(true)} color="primary" variant='outlined' className="caret btn-sm mr-10">에이전트관리</Button>
                            <Button onClick={(e) => history.push('/app/admin/user/new')} color="primary" variant='outlined' className="caret btn-sm mr-10">회원추가 <i className="zmdi zmdi-plus"/></Button>
                            <Button onClick={() => onExport()} color="primary" variant='outlined' className="p-1">내보내기 <i className="zmdi zmdi-open-in-new ml-1"/></Button>
                        </div>
                    </div>
                    <table className="table table-middle table-hover mb-0">
                        <thead>
                        <tr>
                            <th className="w-5">
                                <FormControlLabel
                                    className={'mb-0'}
                                    control={
                                        <Checkbox
                                            indeterminate={selectedUsers > 0 && selectedUsers < users.length}
                                            checked={selectedUsers > 0}
                                            onChange={(e) => onSelectAllUser(e)}
                                            value="all"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                            </th>
                            <th className={'text-center'}>No</th>
                            <th className={'text-center'}>에이전트</th>
                            <th className={'text-center'}>아이디</th>
                            <th className={'text-center'}>총판</th>
                            <th className={'text-center'}>그룹명</th>
                            <th className={'text-center'} style={{width: 150}}>패턴</th>
                            <th className={'text-center'}>상태</th>
                            <th className={'text-center'}>서식가능</th>
                            <th className={'text-center'}>종료일</th>
                            <th className={'text-center'}>시작일</th>
                            <th className={'text-center'}>관리</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users && users.map((user, key) => (
                            <tr key={key}>
                                <td>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={user.checked}
                                                onChange={() => onSelectUser(user)}
                                                color="primary"
                                            />
                                        }
                                    />
                                </td>
                                <td className={'text-center'}>{ (currentPage - 1) * itemsPerPage + key + 1}</td>
                                <td className={'text-center'}>{user.agent ? user.agent.agent_name : ''}</td>
                                <td className={'text-center'}>{user.username}</td>
                                <td className={'text-center'}>{user.distributor ? user.distributor : ''}</td>
                                <td className={'text-center'}>{user.group_name}</td>
                                <td className={'text-center cursor-pointer'} onClick={() => onEditUserPattern(user)}>
                                    <div
                                        className={'text-ellipsis'}
                                        style={{width: 150, color: '#5D92F4'}}
                                    >
                                        {user.pattern}
                                    </div>
                                </td>
                                <td className={'text-center'}>
                                    {
                                        user.active ?
                                            <i data-cy="toggle-active-user" className={'zmdi zmdi-check-circle ml-10 fs-23'} style={{color: 'green', cursor: 'pointer'}}
                                               onClick={() => onToggleActiveUser(user)}/> :
                                            <i data-cy="toggle-active-user" className={'zmdi zmdi-close-circle ml-10 fs-23'} style={{color: 'red', cursor: 'pointer'}}
                                               onClick={() => onToggleActiveUser(user)}/>
                                    }
                                </td>
                                <td className={'text-center'}>
                                    {
                                        user.available_pattern ?
                                            <i data-cy="toggle-active-user" className={'zmdi zmdi-check-circle ml-10 fs-23'} style={{color: 'green', cursor: 'pointer'}}
                                               onClick={() => onToggleAvailablePattern(user)}/> :
                                            <i data-cy="toggle-active-user" className={'zmdi zmdi-close-circle ml-10 fs-23'} style={{color: 'red', cursor: 'pointer'}}
                                               onClick={() => onToggleAvailablePattern(user)}/>
                                    }
                                </td>
                                <td className={'text-center'}>{moment(user.expire_date).format('YYYY-MM-DD hh:mm:ss')}</td>
                                <td className={'text-center'}>{moment(user.created_at).format('YYYY-MM-DD hh:mm:ss')}</td>
                                <td className="list-action text-center">
                                    <button type="button" className="rct-link-btn" onClick={() => history.push('/app/admin/user/edit/' + user.id)}><i className="ti-pencil"/></button>
                                    <button type="button" className="rct-link-btn" onClick={() => onDelete(user)}><i className="ti-close"/></button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot className="border-top">
                        <tr>
                            <td colSpan="100%">
                                <PaginationComponent
                                    totalItems={totalItemCount}
                                    pageSize={itemsPerPage}
                                    onSelect={(page) => setCurrentPage(page)}
                                    maxPaginationNumbers={7}
                                    activePage={currentPage}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                {loading &&
                    <RctSectionLoader/>
                }
            </RctCollapsibleCard>
            <DeleteConfirmationDialog
                ref={deleteConfirmationDialog}
                title="정말 항목을 삭제하시겠습니까?"
                message="이 작업은 되돌릴수 없습니다."
                onConfirm={(password) => deleteUserPermanently(password)}
            />
            <Modal isOpen={addNewUserModal} toggle={() => onAddUpdateUserModalClose()}>
                <ModalHeader toggle={() => onAddUpdateUserModalClose()}>
                    {editUser === null ?
                        '회원추가' : '회원수정'
                    }
                </ModalHeader>
                <ModalBody>
                    {editUser === null ?
                        <EditForm
                            item={addNewUserDetail}
                            onChangeItem={onChangeAddNewUserDetails}
                            agentList={agentList}
                            groupList={groupList}
                        />
                        : <EditForm item={editUser} groupList={groupList} agentList={agentList} onChangeItem={onUpdateUserDetails}/>
                    }

                </ModalBody>
                <ModalFooter>
                    {editUser === null ?
                        <Button variant="contained" className="text-white btn-success" onClick={() => addNewUser()}>추가</Button>
                        : <Button variant="contained" color="primary" className="text-white" onClick={onUserUpdate}>변경</Button>
                    }
                    {' '}
                    <Button variant="contained" className="text-white btn-danger" onClick={() => onAddUpdateUserModalClose()}>취소</Button>
                </ModalFooter>
            </Modal>
            <AgentListModal open={showAddAgentModal} onClose={() => {setShowAddAgentModal(false); getAgentList();}} />
            {
                showPatternModal &&
                <PatternListModal
                    open={showPatternModal} pattern={editUser.pattern}
                    onClose={() => setShowPatternModal(false)}
                    onFinish={(pattern) => onUpdateUserPattern(pattern)}
                />
            }
        </div>
    );
}
