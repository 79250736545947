import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Icon} from '@material-ui/core';
import * as Apis from 'Api';

function PatternTemplateModal({open, onClose, onAddPattern}) {
    const [patternList, setPatternList] = useState([]);

    useEffect(() => {
        getData();
    }, [open]);

    const getData = () => {
        Apis.patternList().then((resp) => {
            setPatternList(resp);
        });
    }

    const onSubmit = (p) => {
        onAddPattern(p);
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg'>
            <DialogTitle className={'pb-0'}>
                <div className={'d-flex flex-row justify-content-between'}>
                    <span>패턴템플릿리스트</span>
                </div>
            </DialogTitle>
            <DialogContent>
                <table className="table table-middle table-hover mb-0" style={{width: 570}}>
                    <thead>
                    <tr>
                        <th className={'text-center'}>No</th>
                        <th className={'text-center'}>패턴명</th>
                        <th className={'text-center'} style={{width: 400}}>패턴</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        patternList.map((v, index) => (
                            <tr key={index} onClick={() => onSubmit(v.pattern)}>
                                <td className={'text-center'}>{index + 1}</td>
                                <td className={'text-center'}>{v.name}</td>
                                <td className={'text-center'}><div className={'text-ellipsis'} style={{width: 400}}>{v.pattern}</div></td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </DialogContent>
        </Dialog>
    );
}

export default PatternTemplateModal;