/**
 * Delete Confirmation Dialog
 */
import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from '@material-ui/core';
import {Col, FormGroup, Input, Label} from "reactstrap";

class ConfirmAdminModal extends Component {

    state = {
        open: false,
        password: ''
    }

    // open dialog
    open() {
        this.setState({ open: true });
    }

    // close dialog
    close() {
        this.setState({ open: false, password: '' });
    }

    render() {
        const { title, message, onConfirm } = this.props;
        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.close()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                    <FormGroup row>
                        <Label for="userEmail" sm={4}>관리자암호</Label>
                        <Col sm={8}>
                            <Input
                                type="password"
                                value={this.state.password}
                                onChange={(e) => this.setState({password: e.target.value})}
                            />
                        </Col>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.close()} className="btn-danger text-white">
                        취소
                    </Button>
                    <Button onClick={() => onConfirm(this.state.password)} className="btn-primary text-white" autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ConfirmAdminModal;
